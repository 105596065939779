import wujiFetch from '@/utils/wujiFetch';
import getXyManagePathPrefix from '@utils/getXyManagePathPrefix';
;
class GlobalConstantService {
    async create(projectId, req) {
        const url = `${getXyManagePathPrefix()}/global-constant?projectid=${projectId}`;
        return await wujiFetch(url, {
            method: 'POST',
            body: JSON.stringify(req),
        });
    }
    async update(projectId, constantId, req) {
        const url = `${getXyManagePathPrefix()}/global-constant/${constantId}?projectid=${projectId}`;
        return await wujiFetch(url, {
            method: 'PATCH',
            body: JSON.stringify(req),
        });
    }
    async delete(projectId, globalConstantId) {
        const url = `${getXyManagePathPrefix()}/global-constant/${globalConstantId}?projectid=${projectId}`;
        return await wujiFetch(url, {
            method: 'DELETE',
        });
    }
    async list(projectId) {
        const url = `${getXyManagePathPrefix()}/global-constant?projectid=${projectId}&size=total&sort=ctime&order=desc`;
        return await wujiFetch(url);
    }
}
export const globalConstantService = new GlobalConstantService();
