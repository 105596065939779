import getXyManagePathPrefix from '@/utils/getXyManagePathPrefix';
import { addPathPrefix } from '@/utils/path';
const getExportProjectUrl = (projectId) => `${location.origin}${addPathPrefix(getXyManagePathPrefix())}/project/export/${projectId}`;
const getPartialExportUrl = (projectId) => `${location.origin}${addPathPrefix(getXyManagePathPrefix())}/project/export/partial/${projectId}`;
export const fetchDiff = async (params) => {
    const { compareFile, compareProjectId, originProjectId, omitDiffFields } = params;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const DiffWorker = await import('./syncDiff.worker').then((module) => module.default);
    const worker = new DiffWorker();
    const buffer = compareFile ? await compareFile.arrayBuffer() : new ArrayBuffer(0);
    const result = [];
    const ret = {
        result,
        authAppIds: [],
        componentHubGroupsList: '',
    };
    return new Promise((resolve, reject) => {
        worker.addEventListener('message', (event) => {
            const { type, data, error } = event.data;
            if (error) {
                worker.terminate();
                reject(new Error(error));
                return;
            }
            if (type === 'compareInfo') {
                ret.authAppIds = data.authAppIds;
                ret.componentHubGroupsList = data.componentHubGroupsList;
                return;
            }
            result.push(...data);
            if (type !== 'done')
                return;
            ret.result = deepFreeze(result, ['diff']);
            resolve(ret);
            worker.terminate();
        });
        worker.addEventListener('error', (event) => {
            reject(new Error(event.message));
            worker.terminate();
        });
        const originProjectUrl = getPartialExportUrl(originProjectId);
        const compareProjectUrl = getExportProjectUrl(compareProjectId);
        const params = {
            buffer, originProjectUrl, compareProjectUrl, originProjectId, compareProjectId,
            force: location.search.includes('forceDiff'), omitDiffFields,
        };
        worker.postMessage(params, [buffer]);
    });
};
export const deepFreeze = (obj, reactiveKeys = []) => {
    if (!obj || typeof obj !== 'object')
        return obj;
    Object.keys(obj).forEach((key) => {
        if (reactiveKeys.includes(key)) {
            const reactive = obj[key];
            // obj[key] 需要保持响应式，但它的属性不需要
            Object.keys(reactive).forEach((prop) => {
                if (typeof reactive[prop] === 'object' && !Object.isFrozen(reactive[prop]))
                    deepFreeze(reactive[prop], []);
            });
            return;
        }
        ;
        if (typeof obj[key] === 'object' && !Object.isFrozen(obj[key]))
            deepFreeze(obj[key], reactiveKeys);
    });
    return Object.freeze(obj);
};
