import { forIn, isEqual, keyBy, omit, pick } from 'lodash';
const rootOmitKeys = ['ctime', 'mtime', '_ctime', '_mtime', 'creator', 'modifier'];
const reusablePageletFields = ['layout', 'stateSchema', 'reportDimensions', 'triggers', 'styleSheets'];
export function analyzePageConfigDiff(oldPageConfig, newPageConfig) {
    const { pageConfig: pageConfig0, ...extra0 } = oldPageConfig;
    const { pageConfig: pageConfig1, ...extra1 } = newPageConfig;
    // if code changed. must force all refresh
    if (!isEqual(omit(extra0, rootOmitKeys), omit(extra1, rootOmitKeys))) {
        return;
    }
    // fine-grained diff for each page
    const pageMap0 = keyBy(pageConfig0, 'id');
    const pageMap1 = keyBy(pageConfig1, 'id');
    // these pagelets can reuse same uc-renderer
    const hmrPagelets = {};
    forIn(pageMap1, (page1, id) => {
        const page0 = pageMap0[id];
        if (!page0)
            return;
        const extra0 = omit(page0, reusablePageletFields);
        const extra1 = omit(page1, reusablePageletFields);
        if (!isEqual(extra0, extra1))
            return;
        const oldPart = pick(page0, reusablePageletFields);
        const hydratedPart = hydrate(oldPart, pick(page1, reusablePageletFields));
        if (reusablePageletFields.every(f => hydratedPart[f] === oldPart[f])) {
            // totally unchanged!
            hmrPagelets[id] = page0;
            return;
        }
        hmrPagelets[id] = { ...hydratedPart, ...extra1 };
    });
    return { hmrPagelets };
}
function hydrate(a, b) {
    if (a === b)
        return a;
    if (typeof a !== 'object' || typeof b !== 'object')
        return b;
    if (a === null)
        return b;
    if (b === null)
        return null;
    // a and b are objects or arrays
    const returnsArray = Array.isArray(b);
    if (Array.isArray(a) !== returnsArray)
        return b;
    const keys = Object.keys(b);
    let justReturnA = (Object.keys(a).length === keys.length);
    const temp = returnsArray ? Array.from(b) : { ...b };
    for (const k of keys) {
        if (!Reflect.has(a, k)) {
            justReturnA = false;
            continue;
        }
        const v = hydrate(a[k], b[k]);
        if (v !== a[k])
            justReturnA = false;
        temp[k] = v;
    }
    return justReturnA ? a : temp;
}
