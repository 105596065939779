import { inject } from '@vue/composition-api';
import Vue from 'vue';
export const MESSAGE_EVENT_NAME = 'message';
export const MODAL_CONTEXT_KEY = Symbol('modal_context');
export function injectModalContext() {
    const ctx = inject(MODAL_CONTEXT_KEY);
    return ctx;
}
export function modalResultFactory(success, message, data) {
    const result = {
        success,
        message,
        data,
    };
    return result;
}
export default function modalFactory(Comp, initOptions = {}) {
    let instance = null;
    const openModal = async function openModal(options) {
        let resolve;
        const promise = new Promise(r => (resolve = r));
        const ctx = {
            resolve: (result) => {
                resolve(result);
                instance.$destroy();
            },
            success: (data) => {
                const result = {
                    success: true,
                    message: 'success',
                    data,
                };
                resolve(result);
                instance.$destroy();
            },
            cancel: () => {
                const result = {
                    success: false,
                    message: 'cancel',
                };
                resolve(result);
                instance.$destroy();
            },
        };
        instance = new Vue({
            parent: initOptions.parent?.(),
            provide: {
                [MODAL_CONTEXT_KEY]: ctx,
            },
            methods: {
                onMessage(data) {
                    resolve(data);
                    if (!initOptions.neverDestroyOnMessage) {
                        console.log('destroy function triggered');
                        this.$destroy();
                        if (initOptions.removeElement) {
                            this.$el.remove();
                        }
                    }
                },
            },
            render() {
                return <Comp options={options} onMessage={this.onMessage}></Comp>;
            },
        });
        const el = document.createElement('div');
        document.body.appendChild(el);
        instance.$mount(el);
        return promise;
    };
    openModal.close = () => {
        instance?.onMessage({
            success: false,
            message: 'cancel',
        });
    };
    return openModal;
}
