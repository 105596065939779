import Entity from './entity/index';
import Start from './entity/Start';
import Approve from './entity/Approve';
import Index from './index';
import NewFlowDetail from './newFlow/Detail.vue';
import { WORKFLOW_ROUTE_NAME, JOB_FLOW_ROUTE_NAME } from '@/config/constant';

export default {
  path: 'workflow',
  component: Index,
  meta: { layoutType: 'header-content' },
  children: [
    {
      path: '',
      redirect: 'entity',
    },
    {
      path: 'start',
      name: 'workflow.start',
      component: Start,
    },
    {
      path: 'restart',
      name: 'workflow.restart',
      component: Start,
    },
    {
      path: 'entity-list',
      name: 'workflow.entity-list',
      component: Entity,
    },
    {
      path: 'entity-detail',
      name: WORKFLOW_ROUTE_NAME,
      component: () => import(/* webpackChunkName: "perm" */ 'tdesign-vue')
        .then(() => Approve),
      // component: Approve,
    },
    {
      path: 'flow/:flowId',
      name: JOB_FLOW_ROUTE_NAME,
      component: ({
        name: 'NewFlowDetailWrapper',
        render(h) {
          const route = this.$route;
          return h(NewFlowDetail, { key: `${route.params.flowId}/${route.query.jobId || ''}` });
        },
      }),
    },
  ],
};
