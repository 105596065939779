<template>
  <div
    ref="refWujiPagelet"
    class="w-sys-pagelet"
    :class="{'w-sys-main-pagelet': main, [`w-sys-${type}-pagelet`]: true}"
  >
    <!-- 加载中 -->
    <template v-if="loading">
      <w-sys-spin
        v-if="main"
        class="loading-icon"
        :tip="loadingText"
      />
    </template>
    <!-- 渲染组件 -->
    <template v-else>
      <component
        :is="codePage"
        v-if="isCodePage"
        ref="code-page-instance"
        :config="config"
        @hook:mounted="codePageMounted"
      />
      <template v-else>
        <page-loading-indicator
          v-if="!hideLoadingIndicator && !projectConfig.uiInfo.hideLoadingIndicator"
          :loading="uicoreLoading"
        />
        <uc-renderer
          v-if="renderUICore"
          :key="ucRendererKey"
          ref="ui-core"
          :data="uicorePlugin.getRootData()"
          :data-schema="uicorePlugin.getRootDataSchema()"
          :ui-lib="uicorePlugin.uiLib"
          :plugins="finalUcPlugins"
          :pagelets="trimedPagelets[pageletId]"
          :components="wContext.components"
          :pagelet-id="pageletId"
          :user-cache-key="uiCoreUserCacheKey"
          :flow-error-logger="$tips"
          :uc-static-renderer="true"
          :component-styles="projectConfig.uiInfo.componentStyles"
          @uc:report="report"
        />
        <MockSwitch v-if="showMockSwitch" />
        <!-- xy 内置弹窗 -->
        <span
          v-for="m of pagelets.modals"
          :key="$route.path + m.id"
        >
          <w-sys-modal
            v-if="wContext.childrenData[m.id].init"
            :id="m.id"
            :ref="'wSysModal_' + m.id"
            :mode="mode"
            :page-info="w.pageInfo"
            :pagelets="trimedPagelets[m.id]"
            :visible.sync="wContext.childrenData[m.id].visible"
            :merge-params="wContext.childrenData[m.id].mergeParams"
            :params="wContext.childrenData[m.id].data"
            @after-close="() => handleModalAfterClose(m.id)"
            @uc:report="modalReport(m.id, $event)"
          />
        </span>
      </template>
    </template>
  </div>
</template>
<script>
import { notification } from 'ant-design-vue';
import { toRefs } from '@vue/composition-api';
import { mapState } from 'vuex';
import { wujiDataSourceProcess } from '@utils/dataSourceHelper';
import { BUILTIN_PAGE_ID, generatedPageIdPrefix,
  WORKFLOW_ROUTE_NAME, JOB_FLOW_ROUTE_NAME } from '@config/constant';
import wujiFetch from '@utils/wujiFetch';
import XyModal from '@components/modal';
import XyPage from '@components/page';
import XyNav from '@components/builtin/xy-nav';
import XySidebar from '@components/builtin/xy-sidebar';
import XyHeader from '@components/builtin/xy-header';
import XyFooter from '@components/builtin/xy-footer';
import XyPageletContainer from '@components/pagelet';
import XyPageletRenderer from '@components/pagelet-renderer';
import PageLoadingIndicator from '@components/pageLoadingIndicator';
import LessCodePlugin from '@tencent/ui-core/exts/LessCode';
import { reportTelemetry } from '@components/telemetry';
import dependencyLoader from '@utils/dependencyLoader';
import { message } from '@/utils/wuji-message';

import componentWrapByUIcore from '../../utils/componentWrapByUIcore';
import {
  getPageRequiredComps,
  getCompsList,
  getWujiCompInstance,
  registerAsyncComponents,
  fetchCompDBInfo,
  COMPONENTS_WITH_UNKNOWN_DEPS,
} from '@/utils/comps-loader';
import pageLoader from '@loaders/page/loader';
import stringInterop from '@tencent/ui-core/lib/utils/stringInterop';
import UICoreXiaoyaoPlugin, { EVENT_DS_READY, EVENT_CREATED, EVENT_MOUNTED } from '../../components/uicorePlugin/UICoreXiaoyaoPlugin';
import { checkPermission } from '../modules/permission/utils';
import { isPlainObject, cloneDeep, omit, groupBy, clone, isEqual } from 'lodash';
import RuntimeReport from '@/reporter/RuntimeReport';
import MockSwitch from '@components/mockSwitch/index';
import uicoreMixin from '@mixins/uicore-mixin';
import { isMiniprogram, isNarrowScreen, setMockMobile } from '@utils/screen';
import { getHubListQuery } from '@/utils/componentHub';
import { useWatermark } from '@components/watermark/useWatermark';
import logger from '@/utils/logger';
import { PageletContext, waitForRendererMounted } from '@utils/global/api';
import { generateCodeDeflate, getLessCodeProxyContext } from '@utils/lessCode';
import componentReport from '@/utils/reporter/component';
import uicoreInitReport from '@/utils/reporter/uicore';
import useDebug from '@pages/project/pageConfig/pagelet-component-editor/composable/use-debug';
import { getDIRComp } from '@components/designerInRuntime';
import { WUJIE_EVENT_NAME, wujieEventEmit } from '@components/wujie-wrapper/event';
import { waitUntilRefReady } from './utils';
import { wEmitter, EventName } from '@/utils/global/event-emitter';
import { isPreviewMode } from '@/composables/pagePreview/use-preview';
import usePageTitle from '@/composables/usePageTitle';
import dataTable from '@/services/dataTable';

const LESSCODE_CUSTOM_CALLBACK = {
  component: 'componentLoaded',
};

export default {
  components: {
    PageLoadingIndicator,
    MockSwitch,
  },
  mixins: [uicoreMixin],
  provide() {
    const { w, wContext, ctx, rootContext } = this;

    if (wContext) {
      wContext.permission = {};
      Object.defineProperties(
        wContext.permission,
        {
          createInPage: {
            get() {
              return wContext.hasPermission('create.page');
            },
            enumerable: true,
          },
          updateInPage: {
            get() {
              return wContext.hasPermission('update.page');
            },
            enumerable: true,
          },
          deleteInPage: {
            get() {
              return wContext.hasPermission('delete.page');
            },
            enumerable: true,
          },
        },
      );

      try {
        const wContextKeys = Object.keys(wContext);
        let ctxKeys = [];
        let rootContextKeys = [];
        if (ctx) ctxKeys = Object.keys(ctx);
        if (rootContext) rootContextKeys = Object.keys(rootContext);

        ctxKeys?.forEach((key) => {
          if (!wContextKeys?.includes(key)) {
            Object.defineProperty(wContext, key, {
              get() {
                return ctx[key];
              },
              set(value) {
                ctx[key] = value;
              },
              enumerable: true,
            });
          }
        });

        rootContextKeys?.forEach((key) => {
          if (!wContextKeys?.includes(key) && !ctxKeys?.includes(key)) {
            Object.defineProperty(wContext, key, {
              get() {
                return rootContext[key];
              },
              set(value) {
                rootContext[key] = value;
              },
              enumerable: true,
            });
          }
        });
      } catch (e) {
        logger.error('[wContext.defineProperty]', e);
      }
    }

    return {
      w,
      ctx: wContext,
      wContext,
    };
  },
  inject: {
    wLayout: {
      from: 'wLayout',
      default: null,
    },
    parentWContext: {
      from: 'wContext',
      default: null,
    },
    rootContext: {
      from: 'rootContext',
      default: null,
    },
    ctx: {
      from: 'ctx',
      default: null,
    },
  },
  props: {
    root: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'runtime',
    },
    type: {
      type: String,
      default: 'content',
    },
    projectConfig: {
      type: Object,
      default() {
        return null;
      },
    },
    config: {
      type: Object,
      default() {
        return {};
      },
    },
    pageList: {
      type: Array,
      default() {
        return [];
      },
    },
    main: {
      type: Boolean,
      default: true,
    },
    mergeParams: {
      type: Boolean,
      default: false,
    },
    params: {
      type: [Array, Object],
      default: () => ([]),
    },
    patchParamsObject: {
      // 在准备 paramsObject 的时候会调用这个
      // （this: sysPagelet, paramsObject: T): T | undefined
      type: Function,
      default: null,
    },
    container: {
      type: Object,
      default: null,
    },
    pageletId: {
      type: String,
      default: 'default',
    },
    hideLoadingIndicator: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { projectConfig: project, config: page } = toRefs(props);
    const { rendererPageletWatermark } = useWatermark({ project, page });

    const {
      addDebugMessageListener,
      removeDebugMessageListener,
    } = useDebug();

    const { handleChangePageTitle } = usePageTitle();

    return {
      rendererPageletWatermark,
      addDebugMessageListener,
      removeDebugMessageListener,
      handleChangePageTitle,
    };
  },
  data() {
    return {
      w: new PageletContext({ mode: this.mode, pageletContainer: this }),
      // 页面片上下文
      wContext: {
        id: this.pageletId, // 当前页面id
        type: 'page', // 当前页面类型(page: 页面; modal: 弹窗; tab: 选项卡)
        env: null,  // 当前应用环境
        config: null, // 页面的基础配置信息
        source: null, // 页面的数据源信息
        projectConfig: this.projectConfig,  // 应用的基础信息
        props: {}, // 容器的配置参数
        data: {}, // 页面运行时的参数
        dataManager: null, // 页面的 DataManager 实例
        components: [], // 组件列表
        childrenData: {}, // 子容器的参数, key 为容器id
        pageList: this.pageList, // 应用的页面列表
        modalList: [], // 页面的弹窗列表
        container: this.container, // 页面的容器实例
        lessCode: null, // 当前ui-core的lessCode上下文
        parent: null, // 父页面ui-core的lessCode上下文
        parentWContext: null, // 父页面的上下文
        isMiniprogram: false, // 是否是小程序
        isNarrowScreen: false, // 是否是窄屏
        // 显示弹窗
        showModal: async (id, data, opt = {}) => {
          logger.info('[modal][pagelet].show.params', id, data, opt);
          const params = opt?.showModal ?? [];
          if (this.wContext.childrenData[id]) {
            this.wContext.childrenData[id].init = true;
            this.wContext.childrenData[id].visible = true;
            this.wContext.childrenData[id].data = data;
            this.wContext.childrenData[id].params = params;

            // [废弃] mergeParams 兼容旧配置
            this.wContext.childrenData[id].mergeParams = opt.mergeParams ?? false;

            this.$nextTick(() => {
              if (this.w?.mode === 'runtime') {
                this.getModalReport(id)?.pv();
              }
              if (this.wContext.lessCode.uc.root.getDtOption()?.open) {
                this.wContext.lessCode.uc.root.getDtBus()
                  .changeUc(this.$refs?.[`wSysModal_${id}`]?.[0]?.wContext?.lessCode?.uc);
              }
            });
            const closeRes = await new Promise((resolve) => {
            // 主页面监听所有弹窗的关闭事件
              this.$once(`dialogClose:${id}`, resolve);
            });

            logger.info('[modal][pagelet].show.res', closeRes);
            return closeRes;
          }
          throw new Error(`找不到[${id}]对应的弹窗`);
        },
        // 隐藏弹窗
        hideModal: async (id, ...args) => {
          logger.info('[modal][pagelet].hide', id, ...args);
          if (this.wContext.childrenData[id]) {
            this.wContext.childrenData[id].visible = false;
          } else if (this.parentWContext) {
            this.parentWContext.hideModal(id, ...args);
          }

          await new Promise((rs) => {
            // 延迟 300ms 后触发一次，兼容页面跳转后弹窗组件没有触发动画和 afterClose 事件导致事件流没有执行完毕
            setTimeout(() => this.handleModalAfterClose(id), 300);
            this.$once(`afterDialogClose:${id}`, () => {
            // 向 lessCode 触发弹窗关闭事件
              this.$emit(`dialogClose:${id}`, ...args);
              if (this.wContext.lessCode.uc.root.getDtOption()?.open) {
                this.wContext.lessCode.uc.root.getDtBus()
                  .changeUc(this.wContext.lessCode.uc);
              }
              if (this.w?.mode === 'runtime') {
                this.getModalReport(id)?.pgOut()
                  ?.destroy();
                this.modalReporters[id] = null;
              }
              const modalInstance = this.getModalInstance(id);

              if (modalInstance?.componentBind?.destroyOnClose) {
                this.wContext.childrenData[id].init = false;
              }

              rs();
            });
          });
        },
        hasPermission: (permission, pageId) => checkPermission(
          this.wContext.user,
          permission,
          pageId || this.config?.pageId,
        ),
        startLoading: this.startLoading,
        stopLoading: this.stopLoading,
        report: this.report,
        // 刷新 ui-core 实例
        reloadUcRenderer: (id = 'default') => {
          this.reloadUcRenderer(id);
        },
        uiCoreUserCacheKey: '',
        pageletInstance: this,
      },
      loading: true,
      loadingText: '',
      data: {},

      // ui-core
      uicorePlugin: new UICoreXiaoyaoPlugin({ parent: this }),
      lessCodePlugin: null,
      // 本地开发的页面
      codePage: null,

      // 改动时需要注意: 这两个变量会通过实例获取
      ucInstance: null,
      ucRendererKey: Date.now().toString(36),

      // 对话框上报器
      modalReporters: {
        // [modalId]: reporter,
      },
      reporter: null,
      // 用来实现uicore的销毁与重建
      // 一般不用改动，默认为true就好
      renderUICore: true,
    };
  },
  computed: {
    ...mapState({
      env: state => state.app.env,
      currentBranch: state => state.branch.currentBranch,
    }),
    branchId() {
      return window.RUNTIME_CONFIGS?.branch || this.currentBranch?.branch || '';
    },
    paramsObject() {
      // 子上下文下 merge 父页面片上下文的data
      const parentData = this.parentWContext?.data;

      let kv = { ...parentData };

      if (Array.isArray(this.params)) {
        this.params.forEach((param) => {
          const { key, value } = param;
          kv[key] = value;
        });
      } else if (isPlainObject(this.params)) {
        kv = this.params;
      }
      let ans = cloneDeep(kv);
      if (typeof this.patchParamsObject === 'function') {
        ans = this.patchParamsObject.call(this, ans) || ans;
      }
      return ans;
    },
    uicoreLoading() {
      const { sources } = this.uicorePlugin;
      if (!sources) return false;

      // eslint-disable-next-line no-restricted-syntax
      for (const k in sources) {
        if (sources[k]?.loading) return true;
      }
      return false;
    },
    allPagelets() {
      let ans = this.config.pageConfig;
      if (!Array.isArray(ans)) ans = [];
      return ans;
    },
    pagelets() {
      const pages = [];
      const modals = [];
      if (!this.allPagelets) {
        return { pages, modals };
      }
      for (let i = 0; i < this.allPagelets.length; i++) {
        const item = this.allPagelets[i];
        if (item.xyType === 'modal' || item.layout.type === 'xy-modal-container') {
          // 弹窗
          modals.push(item);
          // [上下文]
          if (!this.wContext.childrenData?.[item.id]) {
            this.$set(this.wContext.childrenData, item.id, { init: false, visible: false, data: {}, params: [] });
          }
        } else {
          // 页面
          pages.push(item);
        }
      }
      return {
        pages,
        modals,
      };
    },
    isCodePage() {
      if (this.config.type === 'code') {
        const wujiPerformanceIsWujiCodePage = '_wuji_performance_isWujiCodePage';
        window[wujiPerformanceIsWujiCodePage] = true;
        return true;
      }
      return false;
    },
    /** 是否展示底部mock数据生成开关 */
    showMockSwitch() {
      // TODO:根据用户的管理员情况来决定开关
      // 暂时默认关闭 用户想开自己去改window吧
      return this.wContext.env === 'dev' && window.mockSwitchOn;
    },
    /** 提供给uicore的localstorage变量的一个缓存key project_page_rtx_env_pageletId */
    uiCoreUserCacheKey() {
      const key = `${this.config.projectId}_${this.config?.pageId}_${this.w.user.rtx}_${this.w.env}`;
      return key;
    },
    isModalPreview() {
      return this.mode === 'preview' && this.config?.pageType === 'modal';
    },
    trimedPagelets() {
      return groupBy(this.allPagelets, 'id');
    },
  },
  watch: {
    'pagelets.modals': {
      handler(modals) {
        if (Array.isArray(modals)) {
          this.wContext.modalList = modals;
        }
      },
      immediate: true,
      deep: true,
    },
    env: {
      handler(env) {
        this.wContext.env = env;
      },
      immediate: true,
    },
    paramsObject: {
      handler(paramsObject)  {
        this.wContext.data = paramsObject;

        // [废弃] mergeParams 兼容旧配置
        if (this.mergeParams) {
          if (this.wContext?.lessCode) {
            const renderer = this.wContext.lessCode;
            Object.keys(paramsObject).forEach((key) => {
              renderer.$set(renderer.state, key, paramsObject[key]);
            });
          }
        }
      },
      immediate: true,
      deep: true,
    },
    uiCoreUserCacheKey: {
      handler(key) {
        this.wContext.uiCoreUserCacheKey = key;
      },
      immediate: true,
    },
  },
  async created() {
    this.registerXiaoyaoPluginEvent();
    this.lessCodePlugin = new LessCodePlugin({
      deflate: generateCodeDeflate(getLessCodeProxyContext({ w: this.w, $app: this.$app })),
      customCallback: [LESSCODE_CUSTOM_CALLBACK.component],
    });
    logger.info('[keep-alive].pagelet.created');
    // 当 props.main = true 表示为根页面(权限等会根据根页面来判断)
    if (this.main) {
      this.$watch(() => {
        const isMockMobile = this.wContext?.config?.pageAttr?.isMobile ?? false;
        setMockMobile(isMockMobile);
        return {
          isMiniprogram: isMiniprogram(),
          isNarrowScreen: isNarrowScreen() || isMockMobile,
        };
      }, (screen) => {
        this.wContext.isMiniprogram = screen.isMiniprogram;
        this.wContext.isNarrowScreen = screen.isNarrowScreen;
      }, { immediate: true, deep: true });

      this.storeMainPageInfo();
      performance.mark('PAGE_LET_RENDER_FINISH');
      const logger = window.LCPLogger;
      if (this.mode === 'runtime' && logger) {
        logger();
      }
    }

    // 非运行时才监听页面配置的修改
    if (this.w?.mode !== 'runtime' || isPreviewMode()) {
      this.$watch(() => clone(this.config), async (config, prevConfig) => {
        if (config) {
          this.wContext.config = config;
          this.wContext.source = config.pageData;
          this.w.pageInfo = config;

          const omittedFields = ['pageConfig', 'ctime', 'mtime', '_id', '_ctime', '_mtime'];
          if (!prevConfig || !isEqual(omit(config, omittedFields), omit(prevConfig, omittedFields))) {
            await this.initDataManager();
          }
        }
      }, {
        deep: true,
        immediate: true,
      });
    }

    await this.initPage();

    this.$nextTick(this.pageReport);
  },
  activated() {
    logger.info('[keep-alive].pagelet.activated');
    if (this.main && this.w?.mode === 'runtime') {
      this.storeMainPageInfo();
      this.handleChangePageTitle({ page: this.config, title: this.getComputedTitle() });
      this.replacePageMeta();
    }
    this.setRenderer();
  },
  deactivated() {
    this.removeRenderer();
  },
  mounted() {
    logger.info('[keep-alive].pagelet.mounted');
    // 如果是prod环境，异步比较远程版本和当前版本，如果不同则提示刷新
    if (this.env === 'prod' && this.projectConfig?.advanceConfig?.showProdUpdateNotification) {
      this.getCurrentProjectVersion();
    }
    performance.mark('PAGE_LET_RENDER_FINISH_NEW');
    if (this.main) {
      const LCPlogger = window.newLCPLogger;
      if (this.mode === 'runtime' && LCPlogger) {
        LCPlogger();
      }
    }
  },
  beforeDestroy() {
    const wujiComp = getWujiCompInstance();
    wujiComp.removeListener('hmrCompChange', this.hmrCompChange);
    this.removeRenderer();
    this.reporter?.pgOut()?.destroy();
    this.removeDebugMessageListener(this);
    if (this.main && this.mode === 'runtime') {
      wEmitter.emit(EventName.PAGE_BEFORE_DESTROY, this.getEventEmitterContext());
    }
  },
  destroyed() {
    if (this.main && this.mode === 'runtime') {
      wEmitter.emit(EventName.PAGE_DESTROYED, this.getEventEmitterContext());
    }
  },
  methods: {
    getEventEmitterContext(renderer) {
      return {
        id: (renderer ?? this.w?.renderer)?.ucRendererUUID,
        pageInfo: cloneDeep(omit(this.config, ['pageConfig'])),
        renderer,
      };
    },
    registerXiaoyaoPluginEvent() {
      if (this.main && this.mode === 'runtime') {
        this.uicorePlugin.$once(EVENT_CREATED, (renderer) => {
          wEmitter.emit(EventName.PAGE_CREATED, this.getEventEmitterContext(renderer));
        });
        this.uicorePlugin.$once(EVENT_MOUNTED, async (renderer) => {
          await waitForRendererMounted(renderer);
          wEmitter.emit(EventName.PAGE_MOUNTED, this.getEventEmitterContext(renderer));
        });
        this.uicorePlugin.$once(EVENT_DS_READY, (renderer) => {
          wEmitter.emit(EventName.PAGE_DATASOURCE_READY, this.getEventEmitterContext(renderer));
        });
      }
    },
    handleModalAfterClose(id) {
      // 确保弹窗关闭后再触发
      setTimeout(() => this.$emit(`afterDialogClose:${id}`), 0);
    },
    // 设置当前页面的渲染器
    setRenderer() {
      const { type, main, ucInstance: renderer } = this;
      this.$store.commit('layout/setRenderer', { type, main, renderer });
      renderer?.uc && this.$store.commit('layout/collectUcRenderer', { mainRoot: type === 'content' && main, renderer });
    },
    // 移除当前页面的渲染器
    removeRenderer() {
      const { type, main, ucInstance: renderer } = this;
      this.$store.commit('layout/removeRenderer', { type, main, renderer });
      renderer?.uc && this.$store.commit('layout/changeUcRenderer', { mainRoot: type === 'content' && main, renderer });
    },
    // 设置当前页面信息
    storeMainPageInfo() {
      this.$store.commit('runtime/setPageInfo', { currentPageId: this.config.pageId });
      this.$store.commit('runtime/setCurrentPage', this.config);
    },
    hmrCompChange(compKey, compInfo, recreateCanvas = false) {
      const findIndex = this.wContext.components.findIndex(item => item.id === compKey);
      const newComponent = componentWrapByUIcore(compKey, compInfo);
      const newList = [...this.wContext.components];
      if (findIndex > -1) {
        newList[findIndex] = newComponent;
      } else {
        newList.push(newComponent);
      }
      this.wContext.components = Object.seal(newList);
      if (recreateCanvas) {
        this.reloadUcRenderer('*');
      }
    },

    /**
     * 从wujiComp中更新组件信息
     */
    updateCompListFromWujiComp(compKeys) {
      const newList = [...this.wContext.components];
      const wujiComp = getWujiCompInstance();
      compKeys.forEach((compKey) => {
        const findIndex = this.wContext.components.findIndex(item => item.id === compKey);
        if (wujiComp.moduleManage.installedModules[compKey]) {
          const { compInfo } = wujiComp.moduleManage.installedModules[compKey];
          const newComponent = componentWrapByUIcore(compKey, compInfo);
          if (findIndex > -1) {
            newList[findIndex] = newComponent;
          } else {
            newList.push(newComponent);
          }
        }
      });
      this.wContext.components = Object.seal(newList);
    },
    async initDataManager() {
      // eslint-disable-next-line vue/no-mutating-props
      this.config.pageData = this.config.pageData || {
        selectSchemas: [],
        schemaConfig: [],
      };
      // eslint-disable-next-line vue/no-mutating-props
      this.config.pageData.selectSchemas = this.config.pageData.selectSchemas || [];
      // 初始化 selectSchemas; 对新数据进行处理
      if (this.config.pageData.selectSchemas.length > 0 && typeof this.config.pageData.selectSchemas[0] === 'string') {
        const allDataSource = await this.getDataSource();
        await wujiDataSourceProcess(
          this.config.pageData.selectSchemas,
          allDataSource,
          this.config.pageData,
          this.config.projectId,
        );
      }

      const extendTableInfo = {};
      (this.config.pageData.schemaConfig || []).forEach((schema) => {
        extendTableInfo[`${schema.appId}.${schema.schemaId}`] = {
          copyType: schema.copyType,
          deleteType: schema.deleteType,
          deleteField: schema.deleteField,
          deleteValue: schema.deleteValue,
          isKeep: schema.isKeep,
        };
      });

      const DataManager = await import('@tencent/xy-datamanager');

      const dm = new DataManager.default({
        relationData: this.config.dataSourceRelation || [],
        envHost: location.host,
        selectedSchema: this.config.pageData.selectSchemas,
        extendTableInfo,
        projectid: this.config.projectId,
        pageId: this.config.pageId,
        env: this.env,
        mode: 'runtime',
      });

      this.wContext.dataManager = dm;
    },
    // 弹窗标题插值计算
    getComputedTitle() {
      const { title, name } = this.config;
      try {
        return stringInterop(title || name, { ...this.w.renderer, w: this.w });
      } catch (err) {
        return name || this.projectConfig?.name || '';
      }
    },
    // 替换页面的 Meta 信息
    replacePageMeta() {
      try {
        const { metaKeywords, metaDescription } = this.config?.pageAttr ?? {};
        const meta = Array.from(document.getElementsByTagName('meta') ?? []);
        if (metaKeywords) {
          const k = meta.find(el => el.name === 'keywords');
          const content = stringInterop(metaKeywords, this.w.renderer);
          if (k) {
            k.setAttribute('content', content);
          } else {
            const nk = document.createElement('meta');
            nk.setAttribute('name', 'keywords');
            nk.setAttribute('content', content);
            document.getElementsByTagName('head')[0]?.appendChild(nk);
          }
        }

        if (metaDescription) {
          const d = meta.find(el => el.name === 'description');
          const content = stringInterop(metaDescription, this.w.renderer);
          if (d) {
            d.setAttribute('content', content);
          } else {
            const nd = document.createElement('meta');
            nd.setAttribute('name', 'description');
            nd.setAttribute('content', content);
            document.getElementsByTagName('head')[0]?.appendChild(nd);
          }
        }
      } catch (e) {
        logger.error('[pagelet.replacePageMeta]', e);
      }
    },
    // 获取授权数据库表信息
    async getDataSource() {
      try {
        return dataTable.getAuthSchemas({ projectId: this.config.projectId });
      } catch (err) {
        console.error('[pagelet].getDataSource', err);
        return [];
      }
    },

    async initComps() {
      // 初始化组件
      if (
        this.wContext.components.length === 0
        && !this.isCodePage
      ) {
        performance.mark('COMPONENT_INIT_BEGIN');
        const wujiComp = getWujiCompInstance();
        wujiComp.addListener('hmrCompChange', this.hmrCompChange);

        // 收集页面所需要的组件
        const { categories, keys } = getPageRequiredComps(this.wContext.config?.pageConfig);
        // 非主页面的组件，标记为懒加载
        const { keys: nonPrimaryPageKeys } = getPageRequiredComps(this.wContext.config?.pageConfig.slice(1));
        // 取非主页面组件和总体组件的差集，得到可以懒加载的组件
        const lazyLoadComponents = nonPrimaryPageKeys
          .filter(key => !keys.includes(key));

        let lazyLoadRestOfAllComponents = false;
        // 特殊逻辑 如果有编辑器在运行时 就获取全部组件转化为懒加载组件
        if (keys.some(x => COMPONENTS_WITH_UNKNOWN_DEPS.has(x))) {
          lazyLoadRestOfAllComponents = true;
        }
        const compsList = await getCompsList({
          compsKey: keys,
          register: true,
          dependencies: {
            categories,
          },
          componentHubQueryObject: {
            projectId: this.config.projectId,
            projectEnv: this.env,
            hubList: getHubListQuery(this.groups),
            componentRules: this.$route?.query?.componentRules,
            branch: this.branchId,
          },
          forceRefresh: true,
          lazyLoadComponents,
          lazyLoadRestOfAllComponents,
        });

        this.addDebugMessageListener(this);

        // 异步注册没有收集到的组件
        registerAsyncComponents(Object.keys(compsList ?? {}));

        const finalComponents = Object.keys(compsList)
          .map(key => componentWrapByUIcore(key, compsList[key]))
          .filter(item => !!item);
        const designerInRuntime = getDIRComp();
        finalComponents.push(designerInRuntime);
        const builtin = [XyModal, XyPage, XyNav, XySidebar, XyHeader, XyFooter, XyPageletContainer, XyPageletRenderer];
        finalComponents.push(...builtin);
        this.wContext.components = Object.seal(finalComponents);
        performance.mark('COMPONENT_INIT_END');
        componentReport();
      }
      // code-page不需要ui-core
      !this.isCodePage && waitUntilRefReady(this, 'ui-core', (uc) => {
        // 为了确保组件已经渲染完
        // 在这里setTimeout一次
        setTimeout(() => {
          const componentLoadedOptions = uc.renderer.$options[LESSCODE_CUSTOM_CALLBACK.component];
          if (typeof componentLoadedOptions === 'function') {
            componentLoadedOptions(this.wContext.components);
          }
        });
      });
    },
    async initCodePage() {
      // 加载本地开发的页面
      if (this.isCodePage) {
        performance.mark('CODEPAGE_INIT_BEGIN');
        this.codePage = await this.loadCodePage();
        performance.mark('CODEPAGE_INIT_END');
      }
    },
    // 获取并更新渲染器实例
    getAndUpdateRendererInstance() {
      const { type, main } = this;

      // 获取页面实例
      let instance;
      if (this.config?.type === 'code') {
        // 代码开发的页面
        instance = this.$refs?.['code-page-instance'] ?? null;
      } else {
        // 可视化开发的页面
        instance = this.$refs?.['ui-core']?.renderer;
        this.wContext.lessCode = instance;
      }

      if (this.wLayout) {
        if (type !== 'content' || main) {
          this.wLayout[type] = instance;
        }
      }

      this.ucInstance = instance;
      this.w.renderer = instance;
      this.setRenderer();

      return instance;
    },
    async initPage() {
      this.loading = true;
      this.wContext.config = this.config;
      // 在线组件需要使用pagelet来渲染草稿，但是没有pageId, 加载数据源会失败。
      // 所以在此处进行一个兜底
      if (this.wContext.config.pageId === '') this.wContext.config.pageId = 'pageletComponent';
      this.w.pageInfo = this.config;
      this.wContext.source = this.config?.pageData;
      fetchCompDBInfo({
        projectId: this.config.projectId,
        projectEnv: this.env,
        hubList: getHubListQuery(this.groups),
        branch: this.branchId,
      }, true);
      await Promise.all([
        this.initComps(),
        this.initCodePage(),
        this.initPlugins(),
        this.initDataManager(),
        this.initDependencies(),
      ]);
      if (this.config?.pageId) reportTelemetry('pageView', this.config.pageId, { pageTitle: this.config.title });
      this.loading = false;

      // [上下文] 赋值
      this.wContext.parent = this.parentWContext?.lessCode;
      this.wContext.parentWContext = this.parentWContext;
      this.$nextTick(async () => {
        const { main } = this;
        this.getAndUpdateRendererInstance();
        this.renderPageletWaterMark();

        // 运行时主页面逻辑
        if (main && this.w?.mode === 'runtime') {
          // 修改标题
          let title = this.config?.title;
          if (title) {
            // 含有插值表达式
            if (title.indexOf('{{') > -1 && title.indexOf('}}') > -1) {
              title = this.getComputedTitle();

              this.$watch(
                function () {
                  return this.getComputedTitle();
                },
                (newTitle) => {
                  this.handleChangePageTitle({ page: this.config, title: newTitle });
                },
              );
            }
          } else {
            title = this.config?.name;
          }
          this.handleChangePageTitle({ page: this.config, title });

          this.replacePageMeta();
        }
        // 内嵌运行时页面的时候，页面加载完成后，派发事件
        wujieEventEmit(WUJIE_EVENT_NAME.runtimePageLoaded);
      });
    },
    // 渲染水印
    renderPageletWaterMark({ force } = { force: false }) {
      if (this.main && ['runtime', 'preview'].includes(this.w?.mode)) {
        this.rendererPageletWatermark({
          id: 'wuji-pagelet-watermark',
          container: this.$refs.refWujiPagelet,
          renderer: this.getAndUpdateRendererInstance(),
          force,
        });
      }
    },
    async loadCodePage() {
      const { sourceCodeConfig, type } = this.config;
      if (type !== 'code') return null;

      const { id, src } = sourceCodeConfig || {};
      if (!id || !src) {
        message.error('无法加载当前页面，缺少页面id定义');
        return;
      }

      const pageModule = await pageLoader.load(id, src);
      return pageModule;
    },
    reloadUcRenderer(id) {
      if (id === '*') {
        // 刷新所有页面片
        this.ucRendererKey = Date.now().toString(36);
        Object.entries(this.wContext.childrenData).forEach(([key, item]) => {
          // eslint-disable-next-line no-param-reassign
          item.init = false;
          // eslint-disable-next-line no-param-reassign
          item.visible = false;
          // 刷新指定页面片
          const [wSysModal] = this.$refs[`wSysModal_${key}`] ?? [];
          if (wSysModal) {
            wSysModal.reloadUcRenderer();
          }
        });
      } else if (id === this.pageletId) {
        // 刷新主页面
        this.ucRendererKey = Date.now().toString(36);
      } else {
        // 刷新指定页面片
        const [wSysModal] = this.$refs[`wSysModal_${id}`] ?? [];
        if (wSysModal) {
          wSysModal.reloadUcRenderer();
        }
      }

      this.$nextTick(() => {
        this.getAndUpdateRendererInstance();
      });
    },
    startLoading({ text }) {
      this.loading = true;
      this.loadingText = text;
    },
    stopLoading() {
      this.loading = false;
      this.loadingText = '';
    },
    getPageInfo() {
      const isFakePageId = this.config.pageId === BUILTIN_PAGE_ID
        || this.config.pageId.startsWith(generatedPageIdPrefix);
      let contentType = 'normal';
      if (this.$route?.name === WORKFLOW_ROUTE_NAME) contentType = 'workflow';
      else if (this.$route?.name === JOB_FLOW_ROUTE_NAME) contentType = 'flow';

      return {
        type: this.config.type || 'page',
        pageType: isFakePageId ? 'pagelet' : (this.config.pageType || 'content'),
        contentType,
      };
    },
    pageReport() {
      if (this.w?.mode !== 'runtime') return;

      this.reporter = new RuntimeReport({
        projectInfo: {
          projectId: this.config.projectId,
          projectName: this.projectConfig?.name,
          parentId: this.projectConfig?.parentId,
        },
        pageId: this.config.pageId,
        pageInfo: this.getPageInfo(),
        envId: this.env,
        $dom: this.$refs?.['ui-core']?.$el,
        reportDimensions: this.pagelets?.pages[0]?.reportDimensions || [],
        agentReport: this.projectConfig?.advanceConfig?.agentReport ?? false,
      });

      this.reporter.pv();
      uicoreInitReport({ isCodePage: this.isCodePage });
    },
    report(data) {
      this.reporter?.report(data);
    },
    getModalInstance(id) {
      return this.$refs[`wSysModal_${id}`]?.[0];
    },
    getModalReport(id) {
      if (this.modalReporters[id]) return this.modalReporters[id];

      // 弹窗实例
      const modelInstance = this.getModalInstance(id);
      if (!modelInstance) return null;

      // 弹窗实例的 ui-renderer实例
      const modelUiCoreEl = modelInstance?.$refs?.['ui-core']?.$el;
      if (!modelUiCoreEl) return null;

      // 弹窗信息
      const modalInfo = this.pagelets?.modals.find(modal => modal.id === id);
      if (!modalInfo) return null;

      const reporter = new RuntimeReport({
        projectInfo: {
          projectId: this.config.projectId,
          projectName: this.projectConfig?.name,
          parentId: this.projectConfig?.parentId,
        },
        pageId: `${this.config.pageId}/${id}`,
        envId: this.env,
        $dom: modelUiCoreEl,
        reportDimensions: modalInfo.reportDimensions || [],
        agentReport: this.projectConfig?.advanceConfig?.agentReport ?? false,
      });

      this.modalReporters[id] = reporter;

      return reporter;
    },
    modalReport(id, data) {
      this.getModalReport(id)?.report(data);
    },
    codePageMounted() {
      logger.info('codepage mounted');
      this.$nextTick(() => {
        performance.mark('CODEPAGE_FINISH_RENDER');
      });
    },

    async getCurrentProjectVersion() {
      const url = `/api/xy/design/env/${this.env}/version/project/release`;
      const versionData =  await wujiFetch(`${url}?projectid=${this.config.projectId}`, { method: 'GET' });
      if (versionData) {
        const currentVersion = versionData.id;
        const k = '_wuji_project_config_version';
        const preVersion = window[k];
        if (!isNaN(currentVersion) && !isNaN(preVersion) && currentVersion !== preVersion) {
          setTimeout(() => {
            notification.open({
              message: '页面新版本已发布',
              description:
                '您可以点击或者刷新页面以体验！🚀🚀🚀',
              onClick: () => {
                window.location.reload();
              },
            });
          }, 2000);
        }
      }
    },
    // 销毁并重建uicore
    resetUICore() {
      // 如果是编辑时，编辑时的pageConfig会触发这个组件的重建
      // 所以不需要内部二次重建了
      if (this.w?.mode === 'runtime') {
        logger.info('[plugin hmr] resetTrigger');
        this.renderUICore = false;
        this.$nextTick(() => {
          this.renderUICore = true;
        });
      }
    },

    initDependencies() {
      try {
        if (this.isCodePage) return;
        const defaultPagelet = this.wContext.config.pageConfig?.find(page => page.id === 'default');
        return dependencyLoader.load(defaultPagelet.dependencies || []);
      } catch (e) {
        console.error('[pagelet].initDependencies', e);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.w-sys-pagelet {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;

  .loading-icon {
    position: absolute;
    left: 50%;
    top: 38%;
    transform: translate(-50%, -50%);
  }

  > ::v-deep .uc-renderer {
    flex-grow: 1;
  }
}
// 只有主页面才有默认背景色
.w-sys-main-pagelet.w-sys-content-pagelet {
  background: var(--wuji-primary-bg-color);
}
</style>
