import { FieldType } from '../type';
export const schema = {
    id: 'project_ui',
    name: '导航与菜单',
    fields: {
        sidebar: {
            name: '左侧菜单',
            type: FieldType.Array,
            identityField: 'groupName',
            idField: 'groupId',
            getIdentity: groupName => `菜单组【${groupName}】`,
        },
    },
};
