/* eslint-disable no-param-reassign */
import { getUserId } from '@/utils/userInfo';
import { getJSONObject } from '@utils/lang';
import dayjs from 'dayjs';
export var DescriptionType;
(function (DescriptionType) {
    DescriptionType[DescriptionType["Meaningless"] = 0] = "Meaningless";
    DescriptionType[DescriptionType["All"] = 1] = "All";
    DescriptionType[DescriptionType["Schema"] = 2] = "Schema";
    DescriptionType[DescriptionType["Normal"] = 3] = "Normal";
    DescriptionType[DescriptionType["Array"] = 4] = "Array";
    DescriptionType[DescriptionType["Pair"] = 5] = "Pair";
})(DescriptionType || (DescriptionType = {}));
export function revertible(env, branch) {
    return env === 'dev' && (!branch || branch === 'master');
}
export function getRevertDesc(batch) {
    return `revert: ${getUserId()}于${dayjs().format()}${batch ? '批量' : ''}撤销变更`;
}
export var DiffType;
(function (DiffType) {
    DiffType[DiffType["DELETE"] = 0] = "DELETE";
    DiffType[DiffType["ADD"] = 1] = "ADD";
    DiffType[DiffType["UPDATE"] = 2] = "UPDATE";
})(DiffType || (DiffType = {}));
const isJSONStringLike = (item) => {
    if (typeof item === 'string') {
        return (item[0] === '{' && item[item.length - 1] === '}') || (item[0] === '[' && item[item.length - 1] === ']');
    }
    return false;
};
const maxFormatDept = 5; // 最大递归深度
/**
 * 递归解析 JSON 字符串字段
 * @param data - The data to retrieve the formatted diff content from.
 * @returns The formatted diff content.
 */
export const getFormatDiffContent = (data, dept = 1) => {
    if (dept > maxFormatDept)
        return data;
    if (!data)
        return data;
    Object.keys(data).forEach((key) => {
        if (isJSONStringLike(data[key])) {
            data[key] = getJSONObject(data[key]);
        }
        if (typeof data[key] === 'object') {
            return getFormatDiffContent(data[key], dept + 1);
        }
    });
    return data;
};
export function getDataByDiffType(item) {
    return (item.type === DiffType.DELETE ? item.compareData : item.originData) || {};
}
export const defaultConfig = {
    key: 'default',
    getTitle: (item) => {
        const detail = getDataByDiffType(item);
        return `${detail?.[item.nameKey || 'name'] || item.title}`;
    },
};
export function getKey(item) {
    return `${item.schemaKey}:${item.id}`;
}
export const REVERT_CONFIG = {
    dialog: {
        title: '确认撤销以下变更吗？撤销后不可恢复',
        theme: 'danger',
    },
    diff: {
        diffLeftTitle: '撤销前（当前状态）',
        diffRightTitle: '撤销后',
        reverse: true,
    },
};
export const ALL_KEY = 'all';
export const ALL_TITLE = '全部变更';
export const createSchemaTitleMap = () => ({ [ALL_KEY]: ALL_TITLE });
