<template>
  <div
    v-if="isDesignMode"
    class="formPlaceholder"
  >
    <div v-if="sourcesIsEmpty">
      <p>要制作表单，请先添加一个数据源</p>
      <p>
        <a-button
          icon="plus"
          uc-designer-interactive="true"
          :disabled="!renderer.addSourceFromCollection"
          @click="()=>{ renderer.addSourceFromCollection() }"
        >
          添加数据源
        </a-button>
      </p>
    </div>

    <div v-else>
      <p>请选一个数据源，以生成表单？</p>
      <p>
        <a-button
          v-for="(source, sourceId) in sources"
          :key="sourceId"
          :icon="getIconForSource(source)"
          uc-designer-interactive="true"
          @click="()=>{ applySource(sourceId) }"
        >
          {{ sourceId }}
        </a-button>
      </p>
    </div>

    <p>
      <a
        href="#"
        class="notRecommendedCustom"
        uc-designer-interactive="true"
        @click.prevent="applyRawForm"
      >使用空白表单</a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'FormPlaceholder',
  inject: {
    renderer: { from: 'uicore:renderer' },
    designer: { from: 'uicore:designer', default: null },
  },
  computed: {
    isDesignMode() {
      return this.$attrs['data-uc-design-mode'];
    },
    sources() {
      return this.renderer?.sources || {};
    },
    sourcesIsEmpty() {
      return Object.keys(this.sources).length === 0;
    },
  },
  methods: {
    getIconForSource(sourceController) {
      const { config } = sourceController;

      switch (config.type) {
        case 'collection':
          if (config.stateModel === 'list') return 'table';
      }

      return 'form';
    },
    applyRawForm() {
      this.applyLayout({ type: 'w-form' });
    },
    applySource(sourceId) {
      const path = `data.${sourceId}`;
      const fitResult = this.renderer.uc.fitData(path, this.renderer).find(x => x.type === 'editor');

      if (!fitResult?.layout) {
        this.$alert(`操作失败，无法为 ${sourceId} 生成表单，请联系平台方反馈问题`);
        return;
      }

      this.applyLayout(fitResult.layout);
    },
    applyLayout(layout) {
      if (!this.$attrs['data-uc-selected']) return; // 跨作用域的坑
      if (!this.designer) return;
      this.designer.replaceCompWith(this.$attrs['data-uc-path'], layout);
      this.$nextTick(() => {
        // 由于修改绑定的数据源会导致编辑器重刷，我们在nextTick中将焦点重置回编辑器
        // 防止用户此时的cmd+z操作修改了错误的地方
        const rendererElement = this.designer.$el.querySelector('.ucd-editZone.uc-designer-editZone');
        rendererElement?.focus();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.formPlaceholder {
  padding: 10px;
  border-radius: 2px;
  border: 1px dashed #ccc;
  text-align: center;
}

.notRecommendedCustom {
  color: #999;
  cursor: pointer;
  text-decoration: underline;

  &:hover, &:focus {
    color: #666;
  }
}
</style>
