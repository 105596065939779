import { getDetailedDiffTree } from './getDetailedDiffTree';
import { getRoughDiffTree } from './getRoughDiffTree';
export const getDiffTree = (originList, options) => {
    if (!options) {
        return { ...getRoughDiffTree(originList), descriptionMap: {} };
    }
    return getDetailedDiffTree(originList, options);
};
export * from './common';
export * from './getDetailedDiffTree';
export * from './getRoughDiffTree';
