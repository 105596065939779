import { ALL_KEY, ALL_TITLE, createSchemaTitleMap, defaultConfig, getDataByDiffType, getFormatDiffContent } from './common';
/* eslint-disable no-param-reassign */
import { groupBy, isEqual, get, differenceWith, cloneDeep } from 'lodash';
// 旧版树解析 新版稳定后删除
const formatDiffObject = (data, diffConfig, groupData, options) => {
    const originData = diffConfig.group ? data.originData : get(data.originData, diffConfig.key);
    const compareData = diffConfig.group ? data.compareData : get(data.compareData, diffConfig.key);
    const title = diffConfig.getTitle(data);
    options.childNames.push(title);
    const key = diffConfig?.getKey?.(data) || `${data.schemaKey}:${data.id}:${title}`;
    groupData.push({
        ...diffConfig,
        title,
        key,
        path: title,
        checkable: false,
        originData,
        compareData,
        language: diffConfig.language,
        children: diffConfig.group ? getDiffChildren(data, diffConfig.group, options) : [],
        disableTip: true,
        scopedSlots: {
            title: 'custom',
        },
    });
};
export const getDiffChildren = (data, group, options) => {
    const groupData = [];
    group.forEach((diffConfig) => {
        const originData = get(data.originData, diffConfig.key);
        const compareData = get(data.compareData, diffConfig.key);
        if (diffConfig.type === 'array') {
            const diffList = differenceWith(originData, compareData, isEqual);
            diffList.forEach((diffItem) => {
                const originDataItem = originData?.find?.((o) => o?.id === diffItem?.id);
                const compareDataItem = compareData?.find?.((o) => o?.id === diffItem?.id);
                formatDiffObject({
                    ...data,
                    id: data.id,
                    originData: originDataItem,
                    compareData: compareDataItem,
                }, diffConfig, groupData, options);
            });
        }
        else {
            if (!isEqual(originData, compareData)) {
                formatDiffObject(data, diffConfig, groupData, options);
            }
        }
    });
    return groupData;
};
export const DIFF_CONFIG = [
    {
        key: 'project',
        getTitle: (data) => data.title,
    },
    {
        key: 'cgi_front_end_plugin',
        getTitle: (data) => data?.originData?.pluginId ?? '未知插件',
    },
    {
        key: 'project_ui',
        getTitle: (data) => data.title,
        group: [
            {
                key: 'sidebar',
                getTitle: () => '菜单配置',
                language: 'json',
            },
            {
                key: 'navlink',
                getTitle: () => '顶部导航',
                language: 'json',
            },
        ],
    },
    {
        key: 'page',
        getTitle: (item) => {
            const detail = getDataByDiffType(item);
            return `(${item.title})${detail.name} (${detail.path})`;
        },
        group: [
            {
                key: 'pageConfig',
                getTitle: (item) => {
                    const detail = getDataByDiffType(item);
                    return `${detail.name} (${detail.id})`;
                },
                type: 'array',
                language: 'json',
                group: [
                    {
                        key: 'layout',
                        getKey: (item) => {
                            const detail = getDataByDiffType(item);
                            return `${item.schemaKey}:${item.id}:${detail.id}:layout`;
                        },
                        getTitle: () => '页面布局',
                        language: 'json',
                    },
                    {
                        key: 'lessCode',
                        getKey: (item) => {
                            const detail = getDataByDiffType(item);
                            return `${item.schemaKey}:${item.id}:${detail.id}:lessCode`;
                        },
                        getTitle: () => 'LessCode',
                        language: 'javascript',
                    },
                    {
                        key: 'xySources',
                        getKey: (item) => {
                            const detail = getDataByDiffType(item);
                            return `${item.schemaKey}:${item.id}:${detail.id}:xySources`;
                        },
                        getTitle: () => '页面数据',
                        language: 'json',
                    },
                ],
            },
        ],
    },
];
export const getRoughDiffTree = (originList) => {
    const list = cloneDeep(originList);
    const group = groupBy(list, 'schemaKey');
    const childNamesMap = {};
    const schemaTitleMap = createSchemaTitleMap();
    const data = [
        {
            title: ALL_TITLE,
            key: ALL_KEY,
            scopedSlots: {
                title: 'custom',
            },
            children: Object.keys(group).map((schemaKey) => {
                const childNames = [];
                childNamesMap[schemaKey] = childNames;
                const item = group[schemaKey];
                const { title } = item[0] ?? {};
                childNames.push(title);
                schemaTitleMap[schemaKey] = title;
                const config = DIFF_CONFIG.find((config) => config.key === schemaKey) || defaultConfig;
                return {
                    title,
                    key: item[0].schemaKey,
                    scopedSlots: {
                        title: 'custom',
                    },
                    children: item.map((data) => {
                        const originData = getFormatDiffContent(data.originData);
                        const compareData = getFormatDiffContent(data.compareData);
                        return {
                            ...data,
                            scopedSlots: {
                                title: 'custom',
                            },
                            type: data.type,
                            title: config.getTitle(data),
                            path: config.getTitle(data),
                            key: `${data.schemaKey}:${data.id}`,
                            language: 'json',
                            children: config.group ? getDiffChildren({ ...data, originData, compareData }, config.group, { childNames }) : [],
                            originData,
                            compareData,
                        };
                    }),
                };
            }),
        },
    ];
    return { data, childNamesMap, schemaTitleMap };
};
