import { FieldType, Lang } from '../type';
export const schema = {
    id: 'project',
    name: '全局设置',
    fields: {
        advanceConfig: {
            pairs: [
                {
                    list: ['enabledGuard', 'routeGuard'],
                    name: '路由守卫',
                },
                {
                    list: ['showWatermark', 'watermarkConfig'],
                    name: '水印',
                },
            ],
            details: {
                enabledGuard: '开启',
                routeGuard: '源代码',
                showWatermark: '开启',
                watermarkConfig: '配置',
            },
        },
        homePage: {
            name: '主页',
            detailUncheckable: true,
            details: {
                path: '路由',
                query: '参数',
            },
        },
        page403: {
            name: '无权限页',
            detailUncheckable: true,
            details: {
                path: '路由',
                query: '参数',
            },
        },
        lessCodeModules: {
            type: FieldType.DynamicObject,
            detailUncheckable: true,
            details: {
                source: {
                    name: '源代码',
                    lang: Lang.Javascript,
                },
            },
        },
    },
};
