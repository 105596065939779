import { isNil, omitBy } from 'lodash';
export function omitEmpty(data) {
    return omitBy(data, isNil);
}
export const buildMap = (source, array, getKey, formatValue) => {
    if (!array?.length) {
        return source;
    }
    return Object.assign(source, ...array.map(v => ({ [getKey(v)]: formatValue ? formatValue(v) : v })));
};
