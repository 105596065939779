import { shallowRef } from '@vue/composition-api';
export class ReactiveMap {
    refMap;
    syncMap;
    constructor(iterable) {
        this.refMap = shallowRef(new Map(iterable));
        this.syncMap = new Map(iterable);
    }
    has(key) {
        return this.refMap.value.has(key);
    }
    get data() {
        return this.refMap.value;
    }
    get(key) {
        return this.refMap.value.get(key);
    }
    get size() {
        return this.refMap.value.size;
    }
    set(key, value) {
        this.refMap.value.set(key, value);
        this.syncMap.set(key, value);
        this.changeReference();
        return this;
    }
    delete(key) {
        const ret = this.refMap.value.delete(key);
        this.syncMap.delete(key);
        this.changeReference();
        return ret;
    }
    clear() {
        this.refMap.value.clear();
        this.syncMap.clear();
        this.changeReference();
    }
    changeReference() {
        const refMap = this.refMap.value;
        this.refMap.value = this.syncMap;
        this.syncMap = refMap;
    }
}
export function useReactiveMap(iterable) {
    return new ReactiveMap(iterable);
}
