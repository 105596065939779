import { ref } from '@vue/composition-api';
import { uuidv4 } from '@tencent/ui-core/lib/utils';
import { isEqual, noop } from 'lodash';
import { EventEmitter } from 'events';
import { analyzePageConfigDiff } from '../differentialUpdate';
import { ConsumerMessageType, PREVIEW_MODE_QUERY_KEY, ProviderMessageType, CONSUMER_SOURCE, PROVIDER_SOURCE, BROADCAST_CHANNEL_ID, } from './consts';
export const isPreviewMode = () => {
    const querySearch = new URLSearchParams(window.location.search);
    return querySearch.get(PREVIEW_MODE_QUERY_KEY) === '1';
};
export const usePreviewConsumer = (previewSessionKey) => {
    const broadcastChannel = ref(null);
    const previewerId = uuidv4();
    const connected = ref(false);
    const postMessage = (payload, messageType) => {
        broadcastChannel.value?.postMessage({
            messageType,
            source: CONSUMER_SOURCE,
            previewerId,
            payload,
        });
    };
    const fetchPreviewData = () => {
        // 发送消息，通知编辑器已经初始化完成
        // 让编辑器推送第一次数据过来
        postMessage({}, ConsumerMessageType.fetchConfig);
    };
    let isForcingReload = false;
    const requestForceReload = () => {
        isForcingReload = true;
        fetchPreviewData();
    };
    const addPreviewListener = (pagelet) => {
        if (window.RUNTIME_CONFIGS)
            window.RUNTIME_CONFIGS.isPreviewMode = true;
        broadcastChannel.value = new BroadcastChannel(`${BROADCAST_CHANNEL_ID}_${previewSessionKey}`);
        broadcastChannel.value.onmessage = (event) => {
            const { source, payload, messageType, targetPreviewerIds } = event.data;
            if (source !== PROVIDER_SOURCE)
                return;
            events.emit('message', messageType, payload);
            switch (messageType) {
                case ProviderMessageType.ping: {
                    if (targetPreviewerIds && !targetPreviewerIds.includes(previewerId))
                        return;
                    postMessage({ previewerId }, ConsumerMessageType.pong);
                    break;
                }
                case ProviderMessageType.updateDataSource: {
                    if (targetPreviewerIds && !targetPreviewerIds.includes(previewerId))
                        return;
                    events.emit('pageletRecreate', '数据源更新');
                    reloadUcRenderer();
                    break;
                }
                case ProviderMessageType.updatePageConfig: {
                    if (targetPreviewerIds && !targetPreviewerIds.includes(previewerId))
                        return;
                    if (!connected.value)
                        connected.value = true;
                    if (isForcingReload) {
                        isForcingReload = false;
                        pagelet.setProjectConfig(payload.projectInfo);
                        pagelet.setConfig(payload.baseData);
                        reloadUcRenderer();
                        events.emit('pageletRecreate', '强制重新加载');
                    }
                    else if (payload.projectInfo && !isEqual(payload.projectInfo, pagelet.projectConfig)) {
                        pagelet.setProjectConfig(payload.projectInfo);
                        pagelet.setConfig(payload.baseData);
                        reloadUcRenderer();
                        events.emit('pageletRecreate', '项目信息修改');
                    }
                    else {
                        // projectInfo 没有变化，只有 baseData 变化
                        // 可能支持直接HMR，不影响数据源？
                        const diffResult = analyzePageConfigDiff(pagelet.config, payload.baseData);
                        if (!diffResult || Object.keys(diffResult.hmrPagelets).length === 0) {
                            // 不支持，必须全量重刷
                            pagelet.setConfig(payload.baseData);
                            reloadUcRenderer();
                            events.emit('pageletRecreate', '逻辑或数据源变化');
                        }
                        else {
                            // 支持，只需要重新渲染变化的页面片
                            const baseData = { ...payload.baseData };
                            const idsToRecreate = [];
                            const idsToHydrate = [];
                            const oldPageConfigs = pagelet.config.pageConfig;
                            baseData.pageConfig.forEach((pagelet, index) => {
                                const { id } = pagelet;
                                const hydrated = diffResult.hmrPagelets[id];
                                if (hydrated) {
                                    if (hydrated !== oldPageConfigs.find(x => x.id === id))
                                        idsToHydrate.push(id);
                                    baseData.pageConfig[index] = hydrated;
                                }
                                else {
                                    idsToRecreate.push(id);
                                }
                            });
                            pagelet.setConfig(payload.baseData);
                            idsToRecreate.forEach(id => reloadUcRenderer(id));
                            events.emit('pageletHydrate', { idsToRecreate, idsToHydrate });
                        }
                    }
                    break;
                }
                case ProviderMessageType.updateDependency: {
                    if (targetPreviewerIds && !targetPreviewerIds.includes(previewerId))
                        return;
                    pagelet.updateDependency(payload);
                    break;
                }
                case ProviderMessageType.providerCreated: {
                    connected.value = true;
                    postMessage({ previewerId }, ConsumerMessageType.editorCreatedAck);
                    break;
                }
                case ProviderMessageType.providerDestroyed: {
                    connected.value = false;
                    break;
                }
            }
        };
        window.addEventListener('beforeunload', sendClosedMessage);
        fetchPreviewData();
        // 考虑到内嵌页面片自我嵌套的情况，仅处理最外层 pagelet 实例是不够了
        function reloadUcRenderer(pageletId = '*') {
            // WSysPagelet -> UcRenderer -> CRenderer
            const renderers = pagelet.$store.state.layout.ucRenderers;
            for (const renderer of renderers) {
                const wSysPagelet = renderer?.$parent?.$parent;
                if (wSysPagelet?.config?.id !== pagelet.config.id)
                    continue;
                // 找到一个页面片的容器，重新渲染它
                wSysPagelet.reloadUcRenderer(pageletId);
            }
        }
    };
    let sendClosedMessage = () => {
        postMessage({}, ConsumerMessageType.closed);
        window.removeEventListener('beforeunload', sendClosedMessage);
        sendClosedMessage = noop;
    };
    const removePreviewListener = () => {
        sendClosedMessage();
        connected.value = false;
        broadcastChannel.value?.close();
        broadcastChannel.value = null;
    };
    const events = new EventEmitter();
    return {
        isPreviewMode,
        addPreviewListener,
        fetchPreviewData,
        removePreviewListener,
        postMessage,
        events,
        connected,
        requestForceReload,
    };
};
