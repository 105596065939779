<template>
  <div>
    <a-card
      title="审批权限申请流程"
      :bordered="false"
    >
      <apply-form
        ref="applyForm"
        :project-id="projectId"
        :env="env"
        :data="payload"
        :flow-config="flowConfig"
        :editable="matchHandler && entity.flowStatus === 'processing'"
        mode="approval"
      />
      <div v-if="entity.flowStatus === 'processing' && !pluginDisableDefaultAction">
        <a-form-model>
          <div v-if="matchHandler">
            <a-form-model-item
              label="审批意见"
              :label-col="{ span: 8 }"
              :wrapper-col="{ span: 14 }"
            >
              <a-textarea
                v-model="comment"
                placeholder="审批意见"
              />
            </a-form-model-item>
            <a-form-model-item :wrapper-col="{ offset: 8 }">
              <a-button
                type="primary"
                :disabled="!!approving"
                :icon="approving === 'pass' ? 'loading' : ''"
                @click="pass"
              >
                同意
              </a-button>
              <a-button
                style="margin-left:12px"
                :disabled="!!approving"
                :icon="approving === 'reject' ? 'loading' : ''"
                @click="reject"
              >
                驳回
              </a-button>
            </a-form-model-item>
          </div>
          <div v-else-if="isAdmin">
            <a-alert
              banner
              message="你不是审批人之一，无法审批；但管理员可重新指派"
            />
            <a-form-model-item>
              <public-w-dept-rtx-select
                type="staffs"
                multiple
                placeholder="请选择或搜索审批人"
                :value="handlers"
                @change="rtxChange"
              />
              <a-button
                type="primary"
                @click="assignHandlers"
              >
                指派审批人
              </a-button>
            </a-form-model-item>
          </div>
        </a-form-model>
      </div>
    </a-card>
  </div>
</template>

<script>
import { message } from 'ant-design-vue';
import { getUserId } from '@utils/userInfo';
import ApplyForm from './ApplyForm.vue';
import { workflowEntityApi } from '@/services';
import { getWorkflowNotificationPluginList } from '@pages/modules/workflow/config/utils';

export default {
  components: {
    ApplyForm,
  },
  inject: {
    ctx: 'ctx',
  },
  props: {
    projectId: {
      type: String,
      default: '',
    },
    env: {
      type: String,
      default: '',
    },
    entity: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      handlers: [],
      comment: '',
      approving: '',
    };
  },
  computed: {
    payload() {
      if (!this.entity) return null;
      const { payload } = this.entity;
      return JSON.parse(payload);
    },
    flowConfig() {
      if (!this.entity) return null;
      const { flowConf } = this.entity;
      return JSON.parse(flowConf);
    },
    matchHandler() {
      const user = getUserId();
      if (!this.entity) return false;
      return this.entity.handlers.split(',').includes(user);
    },
    isAdmin() {
      return this.ctx?.user?.isAdmin;
    },
    // 插件可以禁止默认的审批行为，全权交由第三方进行审批处理
    pluginDisableDefaultAction() {
      const pluginId = this.flowConfig?.notifyPluginId;
      if (!pluginId) return false;
      const plugin = getWorkflowNotificationPluginList().find(x => x.id === pluginId);
      return !!plugin?.disableDefaultAction;
    },
  },
  methods: {
    async rtxChange(value) {
      this.handlers = value;
    },
    async assignHandlers() {
      if (!this.handlers.length) {
        message.warn('请先选择审批人再指派');
        return;
      }
      const { _id: id } = this.entity;

      try {
        const handlers = this.handlers.join(',');
        await workflowEntityApi.assignHandlers(this.projectId, this.env, id, handlers);
        this.$root.$emit('workflow.assign');
        message.success('指派成功');
        this.handlers = [];
        this.$set(this.entity, 'handlers', handlers);
      } catch (err) {
        const msg = '指派审批人失败';
        message.error(msg);
        console.error(msg, err);
      }
    },
    async pass() {
      const role = this.$refs.applyForm.selectedRoles.join(',');
      if (!role) {
        message.error('同意操作，角色不能为空');
        return;
      }
      const expireAt = +this.$refs.applyForm.form.expireAt || undefined ;
      await this.transition('pass', this.comment, { role, expireAt });
    },
    async reject() {
      await this.transition('reject', this.comment);
    },
    async transition(operation, comment, payload) {
      const { _id: id, status } = this.entity;
      this.approving = operation;
      try {
        await workflowEntityApi.transition(
          this.projectId,
          this.env,
          id,
          { currentStatus: status, operation, comment, payload },
        );
        this.$router.go(0);
      } catch (error) {
        const msg = '审批失败';
        console.error(msg, error);
        message.error(msg);
      } finally {
        setTimeout(() => this.approving = '', 1000);
      }
    },
  },
};
</script>
