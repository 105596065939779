import Vue from 'vue';
import { set, mapValues, pick, isEqual } from 'lodash';
import { message } from 'ant-design-vue';
import { getCollectionState, getCollectionStateSync, methodEditorInjectKey, isJSStateModel } from '@tencent/wuji-source';
import { makeBox, warpPromise, mapBoxValue } from './utils/promiseBox';
import { DataStub } from '@tencent/data-schema-core';
import { tryEval, deepInterop } from '@tencent/ui-core/lib/utils/stringInterop';
import SourceControllerBase from './SourceControllerBase';
import { getAvailableComponent } from '@/utils/comps-loader';

const placeholderDataSchema = { type: 'null' };

const CollectionSourceController = Vue.extend({
  name: 'CollectionSourceController',
  extends: SourceControllerBase,
  inject: {
    methodEditor: methodEditorInjectKey,
  },
  data() {
    const { stateModel, sync } = this.config;
    const { dataSourceConfig } = this.wContext.projectConfig;
    const isSync = isJSStateModel(stateModel) && (dataSourceConfig?.sync || sync);

    return {
      isSync,
      SBox: makeBox(isSync !== true),
      internalLoadingCounter: 0,
    };
  },

  computed: {
    S: mapBoxValue('SBox'),

    data() {
      return this.S?.state;
    },
    dataSchema() {
      return this.S?.state.stateSchema || placeholderDataSchema;
    },
    actions() {
      return this.S?.state.actionSchema?.fields || [];
    },

    loading() {
      return !this.S || this.internalLoadingCounter > 0 || !!this.S?.state?.loading;
    },

    /**
     * @returns {import('@tencent/wuji-source/src/core/state/getCollectionState').IGetCollectionStateOptions}
     */
    stateInitOptions() {
      const projectId = this.wContext.projectConfig.id;
      const envId = this.wContext.env || 'dev';
      const { collectionId, watchers = {}, stateModel } = this.config;
      const watcher = Object.entries(watchers).map(([id, value]) => ({ id, enabled: !!value }));
      const { pageId } = this.wContext.config;
      const options = {
        projectId,
        collectionId,
        envId,
        pageId,
        stateModel,
        stateInitOptions: {
          watcher,
          extraConfig: this.wContext.projectConfig.dataSourceConfig,
          getDynamicParamValues: this.getDynamicParamValues,
          renderer: this.renderer,
          sourceConfig: this.config,
          sourceController: this,
        },
        collectionCache: Boolean(!this.uc?.isDesignMode), // 运行时才使用缓存
      };

      if (this.uc?.isDesignMode && this.methodEditor) {
        options.stateInitOptions.methodExtraCtx = {
          console: this.methodEditor.state.logState.consoleSpy,
          $message: this.methodEditor.state.messageSpy,
        };
      }

      return options;
    },

    // 数据源是否需要同步参数到url
    isNeedSyncUrl() {
      return (
        this.config.syncUrl
        && this.config.stateModel === 'list'
        && this.data
        && Array.isArray(this.data.filter)
        && Array.isArray(this.data.sort)
        && this.data.page !== undefined
      );
    },
    // 需要同步到url上的参数
    synUrlSourceParams() {
      if (this.S?.state) {
        return { filter: this.S.state.filter, sort: this.S.state.sort, page: this.S.state.page };
      } return {};
    },
  },
  created() {
    this.$watch(() => !!this.S, (SReady) => {
      if (!SReady) return;
      this.$emit('load-schema', this);

      const initOldState = async () => {
        const { state } = this.S;
        this.loadInitProps();
        if (this.isNeedSyncUrl) this.addUrlWatcher();
        this.internalLoadingCounter = 0;

        let canLoadData = this.config.initWhenPageLoad ?? true;
        if (canLoadData && this.stateInitOptions.stateModel === 'form') {
          // see wuji-source FormState.ts
          canLoadData = state.id && state.getActionSupported('get');
        }
        try {
          if (canLoadData) {
            await this.loadData();
          } else {
            await this.reset();
          }
          await state.initWatcher();
        } catch (error) {
          throw error;
        } finally {
          this.S?.state?.resolveForReady?.();
        }
      };

      const initJSState = async () => {
        const { state } = this.S;
        if (typeof state.$methods?.init === 'function') {
          await state.$methods?.init();
        }
      };

      this.$nextTick(async () => {
        try {
          const { state } = this.S;
          if (state.isJSStateModel) {
            await initJSState();
          } else {
            // 老的初始化是阻塞的，有些不合理
            await initOldState();
          }
        } catch (error) {
          message.error(`[数据源 ${this.config.id} 初始化出错] ${error}`);
          console.error(`[数据源 ${this.config.id} 初始化出错]`, error);
        }

        this.$emit('ready', this);
      });
    });
    // 配置发生变化就重新初始化一个 collectionState
    this.$watch('stateInitOptions', async (stateInitOptions, prevStateInitOptions) => {
      if (isEqual(stateInitOptions, prevStateInitOptions)) return;

      if (this.isSync) {
        this.SBox.value = getCollectionStateSync(stateInitOptions);
      } else {
        this.SBox = warpPromise(() => getCollectionState(stateInitOptions));
      }
    }, { immediate: true });

    this.$watch(() => this.S?.state?.loading, (stateLoading) => {
      if (stateLoading === false) {
        // 因为现在 load 事件没对齐，所以监听数据源的 loading 状态位
        // 请求完成后，需要重置 lastLoadedData，否则 getChanges 有问题，导致翻页时以为存在没保存的修改
        // 另见： ./SourceControllerBase.js
        this.lastLoadedData = this.getClonedData();
      }
    });
  },

  methods: {
    refreshSchema() {
      this.$emit('load-schema', this);
    },

    internalMinusLoadingCounter() {
      if (this.internalLoadingCounter > 0) this.internalLoadingCounter -= 1;
    },


    fitDataAfterAll(list, req) {
      this.internalFitDataForFormState(list, req);
      this.internalFitDataForAnyState(list, req);
    },

    internalFitDataForAnyState(list, req) {
      const { id: sourceId, stateModel } = this.config;
      if (stateModel !== 'any' && stateModel !== 'any_JS') return;

      // 单接口用的一键表单

      /**
       * @param {'data' | 'query' | 'body' | 'formData' | string} name
       * @param {object} opt
       * @param {boolean} [opt.trimForm] 是否去掉外层的那个 w-form 组件
       * @returns
       */
      const getSubForm = (name, opt = {}) => {
        let paramFormLayout = req.uc.fitData(req.dataStub.getChildStub(name), req.renderer, req.options).find(x => x.type === 'editor')?.layout;
        if (!paramFormLayout) return null;

        const card = paramFormLayout.children?.[0];
        if (card && card.type === 'public-w-card-2v') {
          card.props = { ...card.props, title: true };
        }

        if (
          opt.trimForm
          && paramFormLayout.type === 'w-form'
          && paramFormLayout.children.length === 1
        ) {
          [paramFormLayout] = paramFormLayout.children;
        }

        return paramFormLayout;
      };

      /**
       *
       * @param {object} opt
       * @param {boolean} [opt.validateForm]
       * @returns
       */
      const getRequestButton = (opt = {}) => ({
        type: 'w-paragraph',
        props: { align: 'right' },
        children: [
          {
            type: getAvailableComponent('public', 'w-button'),
            props: { btnText: '发起请求' },
            events: {
              ':handleClick': {
                steps: [
                  !!opt.validateForm && {
                    type: 'uicore:validateForm',
                    params: {
                      target: '',
                      errorMessage: '请按要求填写完整信息',
                      abortIfRejected: true,
                    },
                    backfill: '',
                  },
                  {
                    type: 'xy:invokeDataSourceMethod',
                    params: stateModel === 'any' ? {
                      dataPath: `data.${sourceId}`,
                      methodName: '$request',
                    } : {
                      dataPath: `data.${sourceId}.$methods`,
                      methodName: 'request',
                    },
                    backfill: '',
                  },
                ].filter(Boolean),
              },
            },
            style: { fontSize: '14px' },
          },
        ],
      });

      const dualColumn = !!this.S?.state.features?.hostRequestParams;

      const defaultEditorLayout = {
        type: getAvailableComponent('public', 'w-grid'),
        props: {
          dimension: { colRatio: dualColumn ? '12:6:6' : '12:12', colGap: '16', rowGap: '16' },
          index: 0,
        },
        slots: {},
        children: [
          {
            type: getAvailableComponent('public', 'w-grid-item'),
            children: [
              {
                type: getAvailableComponent('public', 'w-card-2v'),
                props: { title: false, index: 0 },
                children: [
                  {
                    type: 'w-paragraph',
                    componentId: 'wParagraph2',
                    children: [
                      {
                        type: getAvailableComponent('public', 'w-readonly'),
                        style: {
                          lineHeight: '24px',
                          wordWrap: 'break-word',
                          fontSize: '16px',
                          fontWeight: 'bold',
                          'word-wrap': 'break-word',
                        },
                        componentId: 'publicWReadonlyNew',
                        props: { textType: 'h3', value: `${sourceId} 请求` },
                      },

                      ...(dualColumn ? [] : [
                        { type: 'w-spacer', style: { flexGrow: 1 } },
                        getRequestButton({}),
                      ]),
                    ],
                  },
                ],
                slots: {
                  title: [
                    {
                      type: 'w-paragraph',
                      children: [
                        {
                          type: getAvailableComponent('public', 'w-title'),
                          props: {},
                          style: {
                            minWidth: '142px',
                            minHight: '24px',
                            display: 'flex',
                            alignItems: 'center',
                            flexFlow: 'row',
                            fontFamily: 'PingFangSC-Medium',
                            fontSize: '16px',
                            color: 'rgba(0,0,0,0.85)',
                            textAlign: 'left',
                            lineHeight: '24px',
                          },
                          componentId: 'publicWTitle',
                        },
                      ],
                      componentId: 'wParagraph',
                    },
                  ],
                },
                componentId: 'publicWCard2v',
              },
            ],
            props: { index: 0, span: '12', dimension: { colRatio: 'NaN' } },
            style: { minHeight: '24px' },
          },
          dualColumn && {
            type: getAvailableComponent('public', 'w-grid-item'),
            props: { index: 1, span: '6' },
            style: { gap: '18px' },
            children: [{
              type: 'w-form',
              children: [
                req.dataStub.getChildStub('query')?.definedFields?.length > 0 && getSubForm('query', { trimForm: true }),
                !!req.dataStub.getChildStub('body') && getSubForm('body', { trimForm: true }),
                getRequestButton({ validateForm: true }),
              ].filter(Boolean),
            }],
          },
          {
            type: getAvailableComponent('public', 'w-grid-item'),
            props: dualColumn ? { span: '6', index: 2 } : { span: '12', index: 1 },
            style: { gap: '18px' },
            children: [
              getSubForm('data'),
            ].filter(Boolean),
          },
        ].filter(Boolean),
      };

      const defaultEditor = {
        name: '完整的表单',
        type: 'editor',
        order: 100,
        layout: defaultEditorLayout,
      };
      list.unshift(defaultEditor);
    },

    internalFitDataForFormState(list, req) {
      const { id: sourceId, stateModel } = this.config;

      const buttonFinalizeSteps = [];
      if (this.wContext.type === 'modal') {
        buttonFinalizeSteps.push({
          type: 'xy:hideModal',
          params: {},
        });
      }

      if (stateModel !== 'form') {
        return;
      }

      const extraButtons = [];
      const systemActionIds = this.S.stateCtor?.template?.apiTemplates?.map(x => `$${x.name}`) || [];

      this.dataSchema.methods?.forEach((method) => {
        if (systemActionIds.includes(method.id)) return;

        extraButtons.push({
          type: getAvailableComponent('public', 'w-button'),
          props: {
            btnText: `${method.title}`,
          },
          events: {
            click: {
              steps: [
                method.isDangerous && {
                  type: 'uicore:confirm',
                  params: {
                    text: '真的要执行操作吗？',
                    abortIfRejected: true,
                  },
                  backfill: '',
                },
                {
                  type: 'xy:invokeDataSourceMethod',
                  params: {
                    dataPath: `data.${sourceId}`,
                    methodName: method.id,
                  },
                  backfill: '',
                },
                // {
                //   type: 'xy:source:dispatch',
                //   params: {
                //     action: {
                //       sourceId,
                //       action: mehtod.id,
                //     },
                //   },
                //   backfill: '',
                // },
              ].filter(Boolean),
            },
          },
        });
      });

      // 表单模式要添加那些按钮
      let defaultEditorLayout = req.uc.fitData(req.dataStub.getChildStub('formData'))?.find(x => x.type === 'editor')?.layout;
      if (!defaultEditorLayout) return;

      // 额，实际上 formData 可能还不是 object 类型，会是推导出各种奇奇怪怪的玩意儿
      // 如果不是正常情况，那就需要更换最外层组件
      if (!['w-card', 'public-w-card2v', 'w-form', 'public-w-form'].includes(defaultEditorLayout.type)) {
        defaultEditorLayout = {
          type: getAvailableComponent('public', 'w-card'),
          slots: {
            title: [
              {
                type: 'w-paragraph',
                children: [
                  {
                    type: getAvailableComponent('public', 'w-title'),
                    props: { isIcon: true, value: String(req.dataPath) },
                  },
                ],
              },
            ],
          },
          children: [defaultEditorLayout], // 把那个奇怪的组件放到里面来
        };
      }

      const defaultEditor = {
        name: '完整的表单',
        type: 'editor',
        order: 100,
        layout: defaultEditorLayout,
      };

      defaultEditorLayout.children.push({
        type: 'conditionalContainer',
        props: {
          branches: [
            {
              id: 1,
              expr: `!data.${sourceId}.id`,
              name: '创建模式下',
            },
            {
              id: 2,
              expr: `!!data.${sourceId}.id`,
              name: '编辑模式下',
            },
          ],
        },
        slots: {
          'branch-1': [
            {
              type: 'w-paragraph',
              props: { align: 'center' },
              children: [
                {
                  type: getAvailableComponent('public', 'w-button'),
                  props: { btnText: '创建' },
                  events: {
                    click: {
                      steps: [
                        {
                          type: 'uicore:validateForm',
                          params: { target: '' },
                        },
                        {
                          type: 'xy:invokeDataSourceMethod',
                          params: {
                            dataPath: `data.${sourceId}`,
                            methodName: '$create',
                          },
                          backfill: '',
                        },
                        // {
                        //   type: 'xy:source:dispatch',
                        //   params: {
                        //     action: {
                        //       sourceId: `${sourceId}`,
                        //       action: 'create',
                        //     },
                        //   },
                        // },
                        ...buttonFinalizeSteps,
                      ],
                    },
                  },
                },
                ...extraButtons,
              ],
            },
          ],
          'branch-2': [
            {
              type: 'w-paragraph',
              props: { align: 'center' },
              children: [
                {
                  type: getAvailableComponent('public', 'w-button'),
                  props: { btnText: '更新' },
                  events: {
                    click: {
                      steps: [
                        {
                          type: 'uicore:validateForm',
                          params: { target: '' },
                        },
                        {
                          type: 'xy:invokeDataSourceMethod',
                          params: {
                            dataPath: `data.${sourceId}`,
                            methodName: '$update',
                          },
                          backfill: '',
                        },
                        // {
                        //   type: 'xy:source:dispatch',
                        //   params: {
                        //     action: {
                        //       sourceId: `${sourceId}`,
                        //       action: 'update',
                        //     },
                        //   },
                        // },
                        ...buttonFinalizeSteps,
                      ],
                    },
                  },
                },
                {
                  type: getAvailableComponent('public', 'w-button'),
                  props: { btnText: '删除' },
                  events: {
                    click: {
                      steps: [
                        {
                          type: 'uicore:confirm',
                          params: { text: '确定要删除吗？' },
                        },
                        {
                          type: 'xy:invokeDataSourceMethod',
                          params: {
                            dataPath: `data.${sourceId}`,
                            methodName: '$delete',
                          },
                          backfill: '',
                        },
                        // {
                        //   type: 'xy:source:dispatch',
                        //   params: {
                        //     action: {
                        //       sourceId: `${sourceId}`,
                        //       action: 'delete',
                        //     },
                        //   },
                        // },
                        ...buttonFinalizeSteps,
                      ],
                    },
                  },
                },
                ...extraButtons,
              ],
            },
          ],
        },
      });

      list.unshift(defaultEditor);
    },

    // 数据源发生改变同步URL
    // 设计模式下不进行同步，因为设计模式下可以隐藏过滤项
    // 但是由于URL参数存在，会导致隐藏过滤项一直生效
    addUrlWatcher() {
      this.$watch('synUrlSourceParams', (synUrlSourceParams) => {
        const sourceId = `_source_${this.config.id}`;
        const { params, query } = this.$route;
        // Hack: 让 beforeRouteUpdate 守卫函数不刷新 UcRenderer
        params.block_reload_uc_renderer = true;
        const sourceParamsToSting = JSON.stringify(synUrlSourceParams);
        if (this.uc?.isDesignMode && query[sourceId] !== '{}') {
          this.$router.replace({ params, query: { ...query, [sourceId]: '{}' } });
        } else if (query[sourceId] !== sourceParamsToSting) {
          this.$router.replace({ params, query: { ...query, [sourceId]: sourceParamsToSting } });
        };
      }, { immediate: true });
    },

    getClonedData() {
      try {
        // collection 型的数据源做拷贝的时候只需要关系这两
        return JSON.parse(JSON.stringify(pick(this.data, ['listData', 'formData'])));
      } catch (err) {
        console.warn('[XiaoyaoCollectionDS] Cannot clone data for ', this, err);
        return null;
      }
    },

    loadInitProps() {
      if (!this.S) return; // not ready

      const initProps = this.config.initProps || {};
      const { state: { stateSchema, props: propIds } } = this.S;
      const $stateStub = new DataStub({ schema: stateSchema });

      // 获取插值上下文
      const { renderer } = this;

      // 获取url的持久化参数(filter、sort、page)
      let urlSourceParams = {};
      try {
        if (!this.uc?.isDesignMode && this.isNeedSyncUrl) {
          const sourceId = `_source_${this.config.id}`;
          urlSourceParams = JSON.parse(this.$route.query[sourceId] || '{}');
        }
      } catch (error) {
        console.error(error);
      }

      propIds?.forEach((propId) => {
        // 如果URL有配置这个参数，直接从URL来读取(优先级大于用户设置)
        if (urlSourceParams[propId]) {
          return set(this.S.state, propId, urlSourceParams[propId]);
        }

        let rawValue = initProps[propId];
        const $propStub = $stateStub.getStubAtPath(propId);

        // 用户有配置这个参数，所以需要为他做插值
        try { // 避免写失败中断
          rawValue = deepInterop(rawValue, renderer);
        } catch (error) {
          console.error(error);
        }

        // 然后是类型转化
        switch ($propStub.type) {
          case 'number':
          case 'integer':
            rawValue = +rawValue;
            if (Number.isNaN(rawValue)) rawValue = undefined;
            break;

          case 'boolean':
            rawValue = /^(true|1|y)$/i.test(rawValue);
            break;
        }

        // 用户没指定，或者插值遇到奇怪的问题，就，自动生成默认值
        const value = rawValue === undefined ? $propStub.getDefaultValue() : rawValue;
        set(this.S.state, propId, value);
      });
    },

    /** 见 wuji-source */
    getDynamicParamValues(cgiName) {
      /** @type {import('@tencent/wuji-source/src/interfaces/common/IDynamicParams').IDynamicParams[string]} */
      const paramsConfig = this.config.dynamicParams?.[cgiName];
      if (!paramsConfig) return {};

      return mapValues(paramsConfig, (items) => {
        const ans = {};
        items.forEach((it) => {
          const { id, type, value } = it;
          let result;

          switch (type) {
            case 'js':
              result = tryEval(value, this.renderer);
              break;

            case 'const':
              result = value;
              break;
          }

          // eslint-disable-next-line eqeqeq
          if (result == null) return;   // 拒绝 undefined, null, ''
          ans[id] = result;
        });
        return ans;
      });
    },

    async reset() {
      this.$emit('reset', this);
    },

    async loadData() {
      try {
        this.internalLoadingCounter += 1;
        const ans = await this.S?.state.init();
        this.$emit('load', this);
        return ans;
      } finally {
        this.internalMinusLoadingCounter();
      }
    },

    async dispatch(actionName) {
      try {
        this.internalLoadingCounter += 1;
        return await this.S?.state.dispatch(actionName);
      } finally {
        this.internalMinusLoadingCounter();
      }
    },
  },
});

export default CollectionSourceController;
