import { getAvailableComponent } from '@/utils/comps-loader';
import getArrayScopedSlots, { calcSlotByRule } from './getArrayScopedSlots';
// 旧版的卡片布局
export const CARD_LIST_OLD_TYPES = ['readonly', 'complex'];
const PREVIEW = {
    layoutDetail: 'https://vfiles.gtimg.cn/wuji_material/YYVGvkea.svg.svg',
    layoutImage: 'https://vfiles.gtimg.cn/wuji_material/5OO1cGl7.svg.svg',
    layoutList: 'https://vfiles.gtimg.cn/wuji_material/iz6MgCCx.svg.svg',
};
function getFieldBinding(fields, fieldId) {
    if (fields[fieldId]?.id) {
        return `data.item.${fields[fieldId]?.id}`;
    }
    return undefined;
}
// 不同类型的卡片布局的生成函数
const CARD_LIST_LAYOUTS = {
    layoutDetail(source, fields) {
        const layout = {
            type: getAvailableComponent('public', 'w-card'),
            children: [
                {
                    type: 'w-paragraph',
                    children: [
                        {
                            type: getAvailableComponent('public', 'w-avatar'),
                            style: {
                                width: '48px',
                                height: '48px',
                                lineHeight: '48px',
                                borderRadius: '6px',
                            },
                            props: {
                                userId: '{{w.user.userId}}',
                                url: getFieldBinding(fields, 'img') ? {
                                    $expr: getFieldBinding(fields, 'img'),
                                } : 'https://tdesign.gtimg.com/site/avatar-boy.jpg',
                                shape: 'square',
                                type: getFieldBinding(fields, 'img') ? 'img' : 'userId',
                            },
                            bindings: {},
                        },
                        {
                            type: 'public-w-block',
                            props: {
                                dropInVersion: 2,
                            },
                            children: [
                                {
                                    type: 'w-paragraph',
                                    props: {
                                        align: 'left',
                                    },
                                    children: [
                                        {
                                            type: 'public-w-readonly-new',
                                            style: {
                                                height: '22px',
                                                lineHeight: '22px',
                                                fontWeight: 'bold',
                                                display: '-webkit-box',
                                                '-webkit-box-orient': 'vertical',
                                                '-webkit-line-clamp': 1,
                                                lineClamp: 1,
                                                overflow: 'hidden',
                                                wordBreak: 'break-all',
                                                flex: '1',
                                            },
                                            props: {
                                                value: {
                                                    $expr: getFieldBinding(fields, 'title'),
                                                },
                                                textType: 'h4',
                                                isClamp: true,
                                            },
                                        },
                                    ],
                                    style: {
                                        marginBottom: '5px',
                                    },
                                },
                                {
                                    type: 'w-paragraph',
                                    children: [
                                        {
                                            type: 'public-w-readonly-new',
                                            style: {
                                                height: '22px',
                                                lineHeight: '22px',
                                                overflow: 'hidden',
                                                display: '-webkit-box',
                                                '-webkit-box-orient': 'vertical',
                                                '-webkit-line-clamp': 1,
                                                lineClamp: 1,
                                                wordBreak: 'break-all',
                                                flex: '1',
                                            },
                                            props: {
                                                value: {
                                                    $expr: getFieldBinding(fields, 'subTitle'),
                                                },
                                                isClamp: true,
                                            },
                                        },
                                    ],
                                    props: {
                                        align: 'left',
                                    },
                                },
                            ],
                            style: {
                                paddingLeft: '12px',
                                flex: '1',
                            },
                        },
                    ],
                },
                {
                    type: 'w-paragraph',
                    props: {
                        align: 'left',
                    },
                    children: [
                        {
                            type: 'public-w-readonly-new',
                            style: {
                                height: '22px',
                                lineHeight: '22px',
                                fontSize: '12px',
                                width: '100%',
                                color: 'rgba(0,0,0,0.65)',
                                display: '-webkit-box',
                                lineClamp: 1,
                                overflow: 'hidden',
                                wordBreak: 'break-all',
                                '-webkit-box-orient': 'vertical',
                                '-webkit-line-clamp': 1,
                            },
                            props: {
                                isClamp: true,
                                value: {
                                    $expr: getFieldBinding(fields, 'content'),
                                },
                            },
                        },
                    ],
                },
            ],
            props: {
                title: false,
                withFooter: true,
                dropInVersion: 1,
                widthBorder: true,
            },
            slots: {
                footer: [
                    {
                        type: 'w-paragraph',
                        children: [
                            {
                                type: getAvailableComponent('public', 'w-button'),
                                style: {
                                    flexGrow: '1',
                                },
                                props: {
                                    type: 'text',
                                    text: '按钮1',
                                },
                            },
                            {
                                type: getAvailableComponent('public', 'w-button'),
                                style: {
                                    flexGrow: '1',
                                },
                                props: {
                                    type: 'text',
                                    text: '按钮2',
                                },
                            },
                        ],
                        props: {
                            align: 'center',
                        },
                        style: {
                            gap: '0',
                        },
                        extraStyle: {
                            ':not(:last-child)': {
                                '': {
                                    borderRight: '1px solid rgba(0, 0, 0, 0.06)',
                                },
                            },
                        },
                    },
                ],
            },
            style: {
                borderRadius: '6px',
                border: '1px solid rgba(0, 0, 0, 0.06)',
                minWidth: '250px',
                minHeight: '170px',
            },
            extraStyle: {
                '.w-card-footer': {
                    '': {
                        padding: '0px',
                    },
                },
            },
        };
        return {
            title: '详情卡片',
            layout: {
                ...source,
                props: {
                    layoutMode: 'fixedColumnWidth',
                    columnWidth: '380px',
                    autoColumnCountStrategy: 'center',
                },
                scopedSlots: {
                    item: {
                        layout,
                    },
                },
            },
            preview: PREVIEW.layoutDetail,
        };
    },
    layoutImage(source, fields) {
        const layout = {
            type: 'public-w-card-2v',
            props: {
                dropInVersion: 1,
                withFooter: true,
                title: true,
            },
            slots: {
                title: [
                    {
                        type: 'w-paragraph',
                        children: [
                            {
                                type: getAvailableComponent('public', 'w-avatar'),
                                props: {
                                    userId: '{{w.user.userId}}',
                                    size: 'large',
                                    url: getFieldBinding(fields, 'img') ? {
                                        $expr: getFieldBinding(fields, 'img'),
                                    } : 'https://tdesign.gtimg.com/site/avatar-boy.jpg',
                                    type: getFieldBinding(fields, 'img') ? 'img' : 'userId',
                                },
                                style: {
                                    width: '56px',
                                    height: '56px',
                                    lineHeight: '56px',
                                },
                            },
                            {
                                type: 'public-w-block',
                                props: {
                                    dropInVersion: 2,
                                },
                                children: [
                                    {
                                        type: 'public-w-readonly-new',
                                        props: {
                                            textType: 'h3',
                                            isClamp: true,
                                            value: {
                                                $expr: getFieldBinding(fields, 'title'),
                                            },
                                        },
                                        style: {
                                            height: '22px',
                                            lineHeight: '22px',
                                            fontSize: '16px',
                                            display: '-webkit-box',
                                            '-webkit-box-orient': 'vertical',
                                            '-webkit-line-clamp': 1,
                                            lineClamp: 1,
                                            overflow: 'hidden',
                                            wordBreak: 'break-all',
                                        },
                                    },
                                    {
                                        type: 'public-w-readonly-new',
                                        style: {
                                            height: '22px',
                                            lineHeight: '22px',
                                            color: 'rgba(0,0,0,0.6)',
                                            display: '-webkit-box',
                                            '-webkit-box-orient': 'vertical',
                                            '-webkit-line-clamp': 1,
                                            lineClamp: 1,
                                            overflow: 'hidden',
                                            wordBreak: 'break-all',
                                        },
                                        props: {
                                            isClamp: true,
                                            value: {
                                                $expr: getFieldBinding(fields, 'subTitle'),
                                            },
                                        },
                                    },
                                ],
                                style: {
                                    marginLeft: '6px',
                                    '--ui-core-field-margin-vertical': '4px',
                                    flex: '1',
                                    width: '0px',
                                },
                            },
                            {
                                type: getAvailableComponent('public', 'w-button'),
                                style: {
                                    fontSize: 'var(--wuji-font-size, 14px)',
                                },
                                props: {
                                    type: 'link',
                                    text: '操作',
                                },
                            },
                        ],
                        style: {
                            width: '100%',
                        },
                    },
                ],
                footer: [
                    {
                        type: 'w-paragraph',
                        children: [
                            {
                                type: getAvailableComponent('public', 'w-button'),
                                style: {
                                    fontSize: 'var(--wuji-font-size, 14px)',
                                    color: 'rgba(0,0,0,0.9)',
                                    padding: '0px',
                                    width: '32px',
                                    height: '32px',
                                },
                                props: {
                                    type: 'iconbutton',
                                    text: '',
                                    icon: 'check',
                                },
                            },
                            {
                                type: getAvailableComponent('public', 'w-button'),
                                style: {
                                    fontSize: 'var(--wuji-font-size, 14px)',
                                    color: 'rgba(0,0,0,0.9)',
                                    padding: '0px',
                                    width: '32px',
                                    height: '32px',
                                },
                                props: {
                                    type: 'iconbutton',
                                    text: '',
                                    icon: 'search',
                                },
                            },
                            {
                                type: getAvailableComponent('public', 'w-button'),
                                style: {
                                    fontSize: 'var(--wuji-font-size, 14px)',
                                    color: 'rgba(0,0,0,0.9)',
                                    padding: '0px',
                                    width: '32px',
                                    height: '32px',
                                },
                                props: {
                                    type: 'iconbutton',
                                    text: '',
                                    icon: 'edit',
                                },
                            },
                        ],
                        props: {
                            align: 'center',
                        },
                        style: {
                            flexDirection: 'column',
                            height: '64px',
                            padding: '16px 24px 16px 24px',
                            alignContent: 'space-around',
                            gap: '8px',
                            '--td-brand-color': 'transparent',
                            '--td-brand-color-hover': 'var(--td-bg-color-container-hover)',
                            '--td-brand-color-active': 'var(--td-bg-color-container-active)',
                        },
                        extraStyle: {
                            '.ant-btn': {
                                '': {
                                    border: 'none',
                                },
                            },
                        },
                    },
                ],
            },
            extraStyle: {
                '>.w-card-header': {
                    '': {
                        border: 'none',
                    },
                },
                '>.w-card-footer': {
                    '': {
                        padding: '0px',
                        border: 'none',
                    },
                },
                '>.w-card-content': {
                    '': {
                        flex: '1',
                        padding: '0px',
                        display: 'flex',
                        flexDirection: 'column',
                    },
                },
            },
            style: {
                borderRadius: '6px',
                height: '100%',
                border: '1px solid rgb(220,220,220)',
                minWidth: '250px',
                minHeight: '250px',
            },
            children: [
                {
                    type: 'public-w-image',
                    style: {
                        height: '100%',
                    },
                    props: {
                        dropInVersion: 1,
                        mode: 'cover',
                        src: getFieldBinding(fields, 'img') ? {
                            $expr: getFieldBinding(fields, 'img'),
                        } : 'https://tdesign.gtimg.com/site/source/card-demo.png',
                    },
                },
            ],
        };
        return {
            title: '图片卡片',
            layout: {
                ...source,
                props: {
                    layoutMode: 'fixedColumnWidth',
                    columnWidth: '350px',
                    autoColumnCountStrategy: 'grow',
                },
                scopedSlots: {
                    item: { layout },
                },
            },
            preview: PREVIEW.layoutImage,
        };
    },
    layoutList(source, fields) {
        const layout = {
            type: 'w-paragraph',
            children: [
                {
                    type: 'w-tag',
                    style: {
                        wordWrap: 'break-word',
                        color: 'rgba(0,0,0,0.5)',
                        background: 'rgb(243,243,243)',
                        borderRadius: '4px',
                        border: 'none',
                    },
                    props: {
                        color: '#0062B1FF',
                        text: {
                            $expr: getFieldBinding(fields, 'subTitle'),
                        },
                    },
                    events: {},
                },
                {
                    type: 'public-w-readonly-new',
                    style: {
                        height: '22px',
                        lineHeight: '22px',
                        wordWrap: 'break-word',
                        fontSize: '14px',
                        fontWeight: 'normal',
                    },
                    props: {
                        value: {
                            $expr: getFieldBinding(fields, 'title'),
                        },
                    },
                },
                {
                    type: 'w-tag',
                    style: {
                        wordWrap: 'break-word',
                        background: 'color-mix(in srgb,var(--td-brand-color, --wuji-primary-color),#0000 85%)',
                        borderRadius: '4px',
                        marginLeft: 'auto',
                        border: 'none',
                    },
                    props: {
                        color: '#0062B1FF',
                        text: {
                            $expr: getFieldBinding(fields, 'subTitle'),
                        },
                    },
                    events: {},
                },
                {
                    type: 'public-w-readonly-new',
                    style: {
                        color: 'rgba(0,0,0,0.6)',
                        display: '-webkit-box',
                        '-webkit-box-orient': 'vertical',
                        '-webkit-line-clamp': '1',
                        lineClamp: '1',
                        overflow: 'hidden',
                        wordBreak: 'break-all',
                        width: '70px',
                    },
                    props: {
                        value: {
                            $expr: getFieldBinding(fields, 'footer'),
                        },
                        isClamp: true,
                    },
                },
            ],
            style: {
                gap: '10px',
                padding: '8px',
                transition: 'all 0.15s',
            },
            props: {},
            extraStyle: {
                '': {
                    '&:hover': {
                        background: 'rgba(0,0,0,0.06)',
                    },
                },
            },
        };
        return {
            title: '列表卡片',
            layout: {
                ...source,
                props: {
                    columnCount: 1,
                },
                style: {
                    gap: '0',
                },
                scopedSlots: {
                    item: { layout },
                },
            },
            preview: PREVIEW.layoutList,
        };
    },
};
function getCardListLayout(sourceLayout, schema, type) {
    // 兼容旧的卡片布局
    if (CARD_LIST_OLD_TYPES.includes(type)) {
        return {
            ...sourceLayout,
            props: {
                columnCount: 3,
            },
            scopedSlots: getArrayScopedSlots(schema, { type: 'complex' }),
        };
    }
    if (!Object.keys(CARD_LIST_LAYOUTS).includes(type)) {
        throw new Error(`CardListLayout '${type}' not found`);
    }
    const fields = calcSlotByRule(schema.fields ?? []);
    return CARD_LIST_LAYOUTS[type](sourceLayout, fields);
}
export function getAllCardListLayouts(sourceLayout, schema) {
    return Object.keys(CARD_LIST_LAYOUTS)
        .map(type => getCardListLayout(sourceLayout, schema, type));
}
