import Vue from 'vue';
import { getCurrentInstance, watch } from '@vue/composition-api';
export function usePreviewerSnackbar(consumer) {
    const PreviewerSnackbar = () => import('./PreviewerSnackbar.vue');
    const instance = getCurrentInstance();
    const stopWait = watch(consumer.connected, (connected) => {
        if (!connected)
            return;
        stopWait();
        const el = document.createElement('div');
        document.body.appendChild(el);
        new Vue({
            components: { PreviewerSnackbar },
            parent: instance?.proxy,
            render: h => h('PreviewerSnackbar', { props: { consumer } }),
            beforeDestroy() {
                this.$el?.remove();
            },
        }).$mount(el);
    }, { immediate: true });
}
