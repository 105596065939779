<template>
  <w-sys-pagelet
    id="w-sys-page"
    ref="wSysPagelet"
    type="content"
    :root="true"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { permissionApi } from '@/services';
import { pageScrollToTop } from '@utils/browser';
import { message } from '@/utils/wuji-message';

export default {
  name: 'WSysPage',
  provide() {
    return {
      rootContext: this.rootContext,
    };
  },
  inheritAttrs: false, // 把 attrs 透给 src/pages/pagelet/index.vue
  data() {
    return {
      rootContext: {
        mode: this.$attrs?.mode ?? 'runtime',
        registeredComponents: [],
        // 注册组件
        registerComponent: (comp) => {
          const { registeredComponents } = this.rootContext;
          const isExist = registeredComponents.some(c => c === comp);
          if (!isExist) {
            registeredComponents.push(comp);
          }
        },
        // 触发组件事件
        emitComponentEvent: (event, ...args) => {
          const { registeredComponents } = this.rootContext;
          registeredComponents.forEach((c) => {
            if (c && typeof c.$emit === 'function') {
              c.$emit(event, ...args);
            }
          });
        },
        async checkPermission(permission) {
          const { projectId } = this.config;
          try {
            const has = await permissionApi.checkPermission(projectId, this.env, permission);
            return has;
          } catch (error) {
            const msg = '检查权限失败';
            message.error(msg);
            console.error(msg, error);
          }
          return false;
        },
      },
    };
  },
  // 切换页面时默认重置滚动条
  beforeCreate() {
    if (this.$attrs?.main !== false) {
      pageScrollToTop();
    }
  },
  methods: {
    reloadUcRenderer(id) {
      this.$refs.wSysPagelet?.reloadUcRenderer(id);
    },
  },
};
</script>
