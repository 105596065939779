import { defineComponent, ref } from '@vue/composition-api';
import ModalFactory from '@/utils/modalFactory';
const editorDisconnectModal = defineComponent({
    name: 'editorDisconnectModal',
    emits: ['message'],
    setup(_props, { emit }) {
        const visible = ref(true);
        const handleOk = async () => {
            emit('message', {
                success: true,
            });
        };
        const handleCancel = () => {
            emit('message', {
                success: false,
                message: 'cancel',
            });
        };
        return {
            visible,
            handleOk,
            handleCancel,
        };
    },
    render() {
        return (<t-dialog width="450px" visible={this.visible} header="有重复打开的编辑器" closeBtn={false} onCancel={this.handleCancel} closeOnOverlayClick={false}>
        <p class="mb-1">您打开了另一个同页面的编辑器</p>
        <p class="m-0">当前编辑器的预览功能已被暂停使用</p>
        <div slot='footer' class="flex flex-justify-end gap-1">
          <t-button onClick={this.handleOk}>
            激活当前编辑器
          </t-button>
          <t-button theme="default" onClick={this.handleCancel}>关闭提示</t-button>
        </div>
      </t-dialog>);
    },
});
export default ModalFactory(editorDisconnectModal);
