export const SESSION_STORAGE_KEY = 'previewConfig';
export const PREVIEW_MODE_QUERY_KEY = 'previewMode';
export const PROVIDER_SOURCE = 'EDITOR_PREVIEW_DATA_CHANGE';
export const CONSUMER_SOURCE = 'PREVIEWER';
export var ProviderMessageType;
(function (ProviderMessageType) {
    ProviderMessageType["updatePageConfig"] = "updatePageConfig";
    ProviderMessageType["updateDataSource"] = "updateDataSource";
    ProviderMessageType["updateDependency"] = "updateDependency";
    ProviderMessageType["providerDestroyed"] = "providerDestroyed";
    ProviderMessageType["providerCreated"] = "providerCreated";
    ProviderMessageType["ping"] = "ping";
})(ProviderMessageType || (ProviderMessageType = {}));
;
export var ConsumerMessageType;
(function (ConsumerMessageType) {
    ConsumerMessageType["fetchConfig"] = "fetchConfig";
    ConsumerMessageType["closed"] = "closed";
    ConsumerMessageType["pong"] = "pong";
    ConsumerMessageType["editorCreatedAck"] = "editorCreatedAck";
})(ConsumerMessageType || (ConsumerMessageType = {}));
;
export const EDITOR_PREVIEW_SESSION_KEY_PREFIX = 'editor-preview:';
export const BROADCAST_CHANNEL_ID = 'preview-mode-broadcast-channel';
