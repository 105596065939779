var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Getter, State } from 'vue-class-state';
import { FormProp } from './form-prop';
import { cloneDeep, get, groupBy, isObjectLike, omit, set } from 'lodash';
import { nodeDiff3Object, nodeDiff3String } from '@/utils/merge/merge-multi';
import { openMultiConflictModal } from '@/components/merge-multi';
import { message } from '@/utils/wuji-message';
import Vue from 'vue';
import { FormCallResult } from './form-call-result';
import { useRouter } from '@/router/useRouter';
import { bizContainer } from './biz-container';
import { arrayRemove } from '@/utils/lang';
import { vueDeepSet } from '@tencent/ui-core/lib/utils';
import { isNullOrUndefined } from '@tencent/w-flow-common';
export class FormObject {
    options;
    bizMap;
    events = new Vue();
    beforeSaveHooks = [];
    get loading() {
        if (this.loadings.length <= 0) {
            return this.formLoading;
        }
        ;
        const loadingStatus = this.loadings.map(loading => loading());
        return loadingStatus.some(item => !!item) || this.formLoading;
    }
    get changed() {
        const hasChanged = Object.values(this.propsMapByPath).some(prop => prop.changed);
        return hasChanged;
    }
    formDataProxy = {};
    formLoading = false;
    loadings = [];
    propsMapByPath = {};
    get propsMapByKey() {
        return Object.fromEntries(Object.entries(this.propsMapByPath).map(([_key, prop]) => [prop.key, prop]));
    }
    /**
     * 通过loadProps获取，不直接读取
     */
    formData = {};
    baseData = {};
    bizConfig = {};
    /**
     * 记录load过的bizId
     */
    bizIdsSet = new Set();
    get bizIds() {
        return Array.from(this.bizIdsSet);
    }
    constructor(options, bizMap) {
        this.options = options;
        this.bizMap = bizMap;
    }
    getIds() {
        const { route } = useRouter();
        const ids = this.options.ids || {};
        const query = route.value?.query || {};
        const otherIds = omit(ids, ['projectId', 'branchId', 'envId']);
        const projectId = String(query.projectid || ids.projectId || '');
        const branchId = String(query.branchid || ids.branchId || '');
        const envId = String(query.env || ids.envId || 'dev');
        return {
            projectId,
            branchId,
            envId,
            ...otherIds,
        };
    }
    async get(params) {
        const { bizIds } = this;
        const items = [];
        const promise = bizIds.map(async (bizId) => {
            const biz = this.getBiz(bizId);
            const ctx = this.getBizContext(bizId, params);
            try {
                const result = await biz.get(ctx);
                Vue.set(this.formData, bizId, cloneDeep(result.data));
                Vue.set(this.baseData, bizId, cloneDeep(result.data));
                if (result.type) {
                    this.bizConfig[bizId] = { type: result.type };
                }
                items.push({ bizId, succ: true });
            }
            catch (error) {
                items.push({ bizId, succ: false, message: error.message });
            }
        });
        try {
            this.formLoading = true;
            await Promise.all(promise);
        }
        catch (error) {
            throw error;
        }
        finally {
            this.formLoading = false;
        }
        const result = new FormCallResult(items);
        this.events.$emit('get', result);
        return result;
    }
    async save(params, options) {
        const shouldNext = await this.beforeSave();
        if (!shouldNext)
            return;
        const infos = this.getChangedInfo();
        const { success, data, remoteDataMap } = await this.diff3(infos, options);
        if (!success) {
            message.error('合并失败，保存取消');
            return;
        }
        const items = [];
        const promise = Object.entries(data).map(async ([bizId, bizData]) => {
            const ctx = this.getBizContext(bizId, params, remoteDataMap[bizId]);
            const biz = this.getBiz(bizId);
            try {
                const { data } = await biz.save(ctx, bizData); // todo 只提交有变更字段
                delete this.bizConfig[bizId];
                items.push({ succ: true, bizId });
                if (data) {
                    Vue.set(this.formData, bizId, cloneDeep(data));
                    Vue.set(this.baseData, bizId, cloneDeep(data));
                }
            }
            catch (error) {
                items.push({ succ: false, message: error.message, bizId, data: bizData });
            }
        });
        try {
            this.formLoading = true;
            await Promise.all(promise);
        }
        catch (error) {
            throw error;
        }
        finally {
            this.formLoading = false;
        }
        const result = new FormCallResult(items);
        this.events.$emit('save', result);
        return result;
    }
    /**
     * 从已登记的 projectFields 中加载字段
     */
    load(options) {
        const props = {};
        Object.entries(options).forEach(([key, path]) => {
            // @ts-ignore
            props[key] = {
                ...bizContainer.getBizField(path),
                path,
            };
        });
        return this.loadProps(props);
    }
    /**
     * 直接加载多个biz的合并的formData，注意已经取了父字段，子字段就不要取了，三路合并会有冲突
     * @param options
     * @returns
     */
    loadProps(options) {
        const props = {};
        Object.entries(options).forEach((item) => {
            const [key, info] = item;
            const isStr = typeof info === 'string';
            const wholePath = isStr ? info : info.path;
            const [bizId, ...pathArray] = wholePath.split('.');
            let pInfo;
            if (isStr) {
                pInfo = pathArray.join('.');
            }
            else {
                pInfo = {
                    ...info,
                    path: pathArray.join('.'),
                };
            }
            const formProp = this.makeProp(key, bizId, pInfo, this.formDataProxy);
            props[key] = formProp;
            if (!this.bizIdsSet.has(bizId)) {
                this.bizIdsSet.add(bizId);
            }
        });
        return {
            formProps: props,
        };
    }
    /**
     * 加载单个biz的formData, api更加简单点
     * @param bizId
     * @param options
     * @returns
     */
    loadSingleBiz(bizId, options) {
        const formProps = {};
        Object.entries(options).forEach((item) => {
            const [key, info] = item;
            const formProp = this.makeProp(key, bizId, info, this.formDataProxy);
            formProps[key] = formProp;
        });
        return {
            formProps: formProps,
        };
    }
    mergeLoading(loading) {
        if (this.loadings.includes(loading)) {
            throw new Error('loading函数已经存在');
        }
        this.loadings.push(loading);
        return () => {
            arrayRemove(this.loadings, loading);
        };
    }
    getFormDataByPath(path) {
        return get(this.formData, path);
    }
    /**
     * 设置默认值，不触发任何changed变更，可以替代biz的get方法返回默认值
     * @param path
     * @param value
     */
    setFormDataQuietly(path, value) {
        vueDeepSet(this.formData, path, value);
        vueDeepSet(this.baseData, path, value);
    }
    dispose() {
        Object.values(this.propsMapByPath).forEach(prop => prop.dispose());
    }
    /**
     * 根据路径获取 formProp
     * @param path
     * @returns
     */
    getFormPropByPath(path) {
        const formProp = this.propsMapByPath[path];
        if (!formProp) {
            throw new Error(`[FormObject]不允许获取未加载的 formProp, path=${path}`);
        }
        return formProp;
    }
    makeProp(key, bizId, info, formData) {
        if (this.propsMapByKey[key]) {
            const msg = `[FormObject]不允许重复key加载, key=${key}`;
            message.error(msg);
            throw new Error(msg);
        }
        const isStr = typeof info === 'string';
        const path = isStr ? info : info.path;
        const type = isStr ? 'default' : (info.valueType || 'default');
        const format = isStr ? 'normal' : (info.format || 'normal');
        const title = isStr ? '' : (info.title || '');
        const realPath = `${bizId}.${path}`;
        if (this.propsMapByPath[realPath]) {
            throw new Error(`不允许重复加载, key=${key}, bizId=${bizId}, path=${path}`);
        }
        const formProp = new FormProp(key, bizId, path, type, format, title, () => this.formData, () => this.baseData);
        // this.propsMapByPath[realPath] = formProp;
        Vue.set(this.propsMapByPath, realPath, formProp);
        Object.defineProperty(formData, key, {
            get: () => formProp.value,
            set: value => formProp.value = value,
            enumerable: true,
        });
        return formProp;
    }
    getChangedInfo() {
        const props = Object.values(this.propsMapByPath);
        const changedProps = props.filter(prop => prop.changed);
        const group = groupBy(changedProps, 'bizId');
        const infos = Object.entries(group).map(([bizId, props]) => {
            const data = props.reduce((prev, prop) => {
                const { rootFieldName, bizData } = prop;
                const value = get(bizData, rootFieldName);
                set(prev, rootFieldName, value);
                return prev;
            }, {});
            return {
                bizId,
                props,
                data,
            };
        });
        return infos;
    }
    getBiz(bizId) {
        const biz = this.bizMap.get(bizId);
        if (!biz) {
            throw new Error(`biz ${bizId} not found`);
        }
        return biz;
    }
    getBizContext(bizId, params, remoteData) {
        const ids = this.getIds();
        const { extra = {} } = params || {};
        return {
            ...ids,
            extra,
            rawData: this.formData[bizId],
            remoteData,
            type: this.bizConfig[bizId]?.type,
        };
    }
    async diff3(infos, options) {
        const { formData, baseData } = this;
        const source = [];
        const normalLocal = {};
        const normalRemote = {};
        const normalBase = {};
        const remoteDataMap = {};
        // 获取remote数据
        const promises = infos.map(async (info) => {
            const { bizId, props } = info;
            const biz = this.getBiz(bizId);
            const result = await biz.getRemote(this.getBizContext(bizId));
            remoteDataMap[bizId] = result;
            const bizRemoteData = result;
            const bizLocalData = get(formData, bizId);
            const bizBaseData = get(baseData, bizId);
            props.forEach((prop) => {
                // key 貌似没有合适，先用key吧
                const { key } = prop;
                const remoteValue = prop.getValue(bizRemoteData);
                const localValue = prop.getValue(bizLocalData);
                const baseValue = prop.getValue(bizBaseData);
                if (prop.format === 'normal') {
                    // 普通字段合并到同一个json diff视图
                    normalRemote[key] = remoteValue;
                    normalLocal[key] = localValue;
                    normalBase[key] = baseValue;
                }
                else {
                    if (isNullOrUndefined(remoteValue)) {
                        source.push({
                            path: prop.formDataPath,
                            title: prop.title,
                            conflict: false,
                            result: localValue,
                            local: checkJson(localValue),
                            type: prop.format,
                        });
                        return;
                    }
                    // 其他格式的字段，单独一个diff视图
                    const localStr = checkJson(localValue);
                    const remoteStr = checkJson(remoteValue);
                    const baseStr = checkJson(baseValue);
                    const singleResult = nodeDiff3String({
                        local: localStr,
                        base: baseStr,
                        remote: remoteStr,
                        type: prop.format,
                    });
                    source.push({
                        path: prop.formDataPath,
                        title: prop.title,
                        conflict: singleResult.conflict,
                        result: singleResult.result,
                        local: localStr,
                        type: prop.format,
                    });
                }
            });
        });
        await Promise.all(promises);
        const normalResult = nodeDiff3Object({
            local: normalLocal,
            base: normalBase,
            remote: normalRemote,
            type: 'json',
        });
        if (Object.keys(normalResult.result).length > 0) {
            source.unshift({
                path: normalDataPath,
                title: this.options.title || '基础数据',
                conflict: normalResult.conflict,
                result: normalResult.result,
                local: normalLocal,
                type: 'json',
            });
        }
        const finalData = {};
        const result = await getConflictResult(source, options);
        const { success, data } = result;
        if (success) {
            // 通过  ISourceItem 的 result 进行字段回算合并
            const originData = cloneDeep(this.formData);
            data?.forEach((item) => {
                let { result } = item;
                if (item.type === 'json' && typeof result === 'string')
                    result = JSON.parse(result);
                if (item.path === normalDataPath) {
                    Object.entries(result).forEach(([key, value]) => {
                        const prop = this.propsMapByKey[key];
                        if (!prop) {
                            console.error(`未找到字段prop配置，key=${key}`);
                            return;
                        }
                        ;
                        shallowSet(finalData, originData, prop.formDataPath, value);
                    });
                }
                else {
                    shallowSet(finalData, originData, item.path, result);
                }
            });
        }
        return {
            success,
            data: finalData,
            remoteDataMap,
        };
    }
    async beforeSave() {
        if (this.beforeSaveHooks.length <= 0) {
            return true;
        }
        let shouldNext = false;
        const next = (should = true) => {
            shouldNext = should;
        };
        for (const hook of this.beforeSaveHooks) {
            await hook(next);
            if (!shouldNext)
                break;
        }
        return shouldNext;
    }
}
__decorate([
    Getter
], FormObject.prototype, "loading", null);
__decorate([
    Getter
], FormObject.prototype, "changed", null);
__decorate([
    State
], FormObject.prototype, "formLoading", void 0);
__decorate([
    State
], FormObject.prototype, "loadings", void 0);
__decorate([
    State
], FormObject.prototype, "propsMapByPath", void 0);
__decorate([
    Getter
], FormObject.prototype, "propsMapByKey", null);
__decorate([
    State
], FormObject.prototype, "formData", void 0);
__decorate([
    State
], FormObject.prototype, "baseData", void 0);
const normalDataPath = '@/normalDataPath';
function shallowSet(finalData, originData, path, value) {
    const [bizId, headName, ...others] = typeof path === 'string' ? path.split('.') : path;
    if (others.length === 0) {
        set(finalData, path, value);
        return;
    }
    const headPath = [bizId, headName];
    const headValue = get(originData, headPath); // 不copy，保证每次拿到同一份数据
    if (isObjectLike(headValue) && others.length > 0) {
        // 保留biz第一级的json字段
        set(headValue, others, value);
        set(finalData, headPath, headValue);
    }
    else {
        set(finalData, path, value);
    }
}
function checkJson(value) {
    if (isObjectLike(value)) {
        return JSON.stringify(value, undefined, 2);
    }
    if (isNullOrUndefined(value)) {
        return '';
    }
    ;
    return value;
}
const getConflictResult = async (source, options) => {
    const hasConflict = source.some(item => item.conflict === true);
    if (hasConflict) {
        options?.onConflictStart?.();
        const ret = await openMultiConflictModal({
            source,
            title: '修改合并存在冲突, 请手动解决冲突后提交合并',
        });
        options?.onConflictEnd?.();
        return ret;
    }
    return {
        success: true,
        data: source,
    };
};
