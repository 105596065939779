/* eslint-disable no-param-reassign */
import { groupBy, isEqual, get, cloneDeep, isPlainObject, isUndefined, isNil, omit, pick, last } from 'lodash';
import { schemaContainer } from '@/workbench/modules/schema-registry';
import { FieldType, SchemaType } from '@/workbench/modules/schema-registry/type';
import { DescriptionType, DiffType, getFormatDiffContent, getRevertDesc, ALL_KEY, ALL_TITLE, createSchemaTitleMap, } from './common';
const getDetailedKey = (item) => `${item.schemaKey}:${item.id}`;
const getDiffType = ({ originData, compareData }) => {
    const noCur = isNil(originData);
    const noPrev = isNil(compareData);
    if (noPrev && noCur) {
        return undefined;
    }
    if (noPrev) {
        return DiffType.ADD;
    }
    if (noCur) {
        return DiffType.DELETE;
    }
    return DiffType.UPDATE;
};
const withSlots = (data) => Object.assign(data, {
    scopedSlots: {
        title: 'custom',
    },
});
const getUnionFields = (data) => {
    const anyData = data.originData || data.compareData;
    const isObj = isPlainObject(anyData);
    if (!isObj) {
        return [];
    }
    const fields = [...new Set([].concat(...[data.originData, data.compareData].map(getSortedKeys)))];
    return fields;
};
const mergeCheckable = (detailInfo, parentData) => !(detailInfo?.uncheckable ?? parentData.childUncheckable ?? !parentData.checkable);
const createAnyGet = (...dataArr) => (path) => {
    let ret = undefined;
    dataArr.some((data) => {
        const cur = get(data, path);
        if (!isUndefined(cur)) {
            ret = cur;
            return true;
        }
        return false;
    });
    return ret;
};
const getArrayDiff = (data, diffOptions) => {
    const { descriptionMap, indivisibleSchemaSet, childNames } = diffOptions;
    const diff = {
        matched: false,
        fields: [],
    };
    if (!data.field) {
        return diff;
    }
    const fieldDetailInfo = schemaContainer.getFieldDetailInfo(data.schemaId, data.fieldPath);
    if (fieldDetailInfo?.type === FieldType.Array && !data.isArrayItem) {
        const originMap = {};
        const compareMap = {};
        const mapList = [originMap, compareMap];
        const [originIdentities, compareIdentities] = [data.originData, data.compareData].map((v, mapIdx) => v?.map?.((item, idx) => {
            const identity = fieldDetailInfo.getIdentity?.(item);
            if (identity) {
                mapList[mapIdx][identity] = { data: item, idx };
            }
            return identity;
        }) ?? []);
        const identities = [...new Set(originIdentities.concat(compareIdentities))];
        const fields = identities.map((identity) => {
            const { data: originData, idx: originIdx } = originMap[identity] ?? {};
            const { data: compareData, idx: compareIdx } = compareMap[identity] ?? {};
            if (isEqual(originData, compareData)) {
                return false;
            }
            const anyGet = createAnyGet(originData, compareData);
            const arrayItemDiff = {
                appId: data.appId,
                schemaId: data.schemaId,
                modifier: data.modifier,
                mtime: data.mtime,
                field: data.field,
                diffId: data.diffId,
                title: identity,
                originData,
                compareData,
                id: identity,
                schemaKey: data.key,
                path: `${data.path}${getSeparator(data)}${anyGet(fieldDetailInfo.idField)}`,
                language: 'json',
                checkable: mergeCheckable(fieldDetailInfo, data),
                key: '',
                fieldPath: data.fieldPath,
                isArrayItem: true,
                childUncheckable: getDetailUncheckable(fieldDetailInfo, data, true),
                disableTip: true,
            };
            childNames?.push(arrayItemDiff.title);
            arrayItemDiff.key = `${getDetailedKey(arrayItemDiff)}.${originIdx}.${compareIdx}`;
            if (indivisibleSchemaSet.has(getDiffIdentity(arrayItemDiff))) {
                arrayItemDiff.checkable = false;
            }
            descriptionMap[arrayItemDiff.key] = descriptionMap[data.key].concat({
                propType: DescriptionType.Array,
                originIdx,
                compareIdx,
            });
            arrayItemDiff.children = getFields(arrayItemDiff, diffOptions);
            arrayItemDiff.type = getDiffType(arrayItemDiff);
            return withSlots(arrayItemDiff);
        });
        diff.matched = true;
        diff.fields = fields.filter(Boolean);
    }
    return diff;
};
const getDynamicObjectDiff = (data, diffOptions) => {
    const diff = {
        matched: false,
        fields: [],
    };
    if (!data.field) {
        return diff;
    }
    const fieldDetailInfo = schemaContainer.getFieldDetailInfo(data.schemaId, data.fieldPath);
    if (fieldDetailInfo?.type === FieldType.DynamicObject && !data.isDynamicObjectItem) {
        diff.matched = true;
        diff.fields = getFields(data, diffOptions, {
            withProperty: () => ({ isDynamicObjectItem: true }),
            getFieldPath: (_, { parentData }) => getItemFieldPath(parentData),
            getName: (_, { field }) => field,
        }).map(fieldDiff => ({ ...fieldDiff, type: getDiffType(fieldDiff) }));
    }
    return diff;
};
const getSeparator = (parentData) => {
    if (parentData.isSchema) {
        return '';
    }
    return '/';
};
const getPairDiff = (data, pairs, getChildren, diffOptions) => pairs
    .map((pair) => {
    const { list, name } = pair;
    const { descriptionMap, childNames } = diffOptions;
    const originData = pick(data.originData, list);
    const compareData = pick(data.compareData, list);
    if (isEqual(originData, compareData)) {
        return false;
    }
    const children = getChildren(list);
    const keys = children.map(child => child.key);
    const pairDiff = {
        title: name,
        key: '',
        modifier: data.modifier,
        diffId: data.diffId,
        mtime: data.mtime,
        schemaKey: data.key,
        schemaId: data.schemaId,
        appId: data.appId,
        path: `${data.path}${getSeparator(data)}[${list.join(',')}]`,
        language: 'json',
        children,
        disableTip: true,
        id: name,
        checkable: data.checkable,
        originData,
        compareData,
    };
    pairDiff.key = getDetailedKey(pairDiff);
    childNames?.push(name);
    descriptionMap[pairDiff.key] = descriptionMap[data.key].concat({
        propType: DescriptionType.Pair,
        keys,
    });
    return withSlots(pairDiff);
})
    .filter(Boolean);
const diffFuncs = [getArrayDiff, getDynamicObjectDiff];
const getSpecialDiff = (...args) => {
    let specialFields = [];
    const isSpecialDiff = diffFuncs.some((getDiff) => {
        const diff = getDiff(...args);
        if (diff.matched) {
            specialFields = diff.fields;
        }
        return diff.matched;
    });
    return {
        matched: isSpecialDiff,
        fields: specialFields,
    };
};
const specialFieldTypes = [FieldType.Array, FieldType.DynamicObject];
const isSpecialFieldType = (fieldDetailInfo) => specialFieldTypes.includes(fieldDetailInfo?.type);
const getDetailUncheckable = (fieldDetailInfo, parentData, isSpecialDetail) => {
    const normalUncheckable = fieldDetailInfo?.detailUncheckable ?? parentData.childUncheckable;
    if (isSpecialFieldType(fieldDetailInfo)) {
        if (isSpecialDetail) {
            return normalUncheckable;
        }
        return fieldDetailInfo?.specialUncheckable ?? parentData.childUncheckable;
    }
    return normalUncheckable;
};
const getItemFieldPath = (item) => item.field && item.fieldPath;
const combineFieldPath = (parentData, field) => `${getItemFieldPath(parentData)}.${field}`;
const getFields = (data, { singleEffectOptions, ...diffOptions }, specialOptions) => {
    const { getFieldInfo, descriptionMap, indivisibleSchemaSet, childNames } = diffOptions;
    const { fieldsControl: { pickFields, omitFields } = {}, pairs = [] } = singleEffectOptions ?? {};
    const pickFieldSet = pickFields ? new Set(pickFields) : undefined;
    const omitFieldSet = omitFields ? new Set(omitFields) : undefined;
    if (!specialOptions) {
        const specialDiff = getSpecialDiff(data, { ...diffOptions, singleEffectOptions });
        if (specialDiff.matched) {
            return specialDiff.fields;
        }
    }
    const { getName, getFieldPath, withProperty, childrenSpecialDiffOptions } = specialOptions ?? {};
    const fields = getUnionFields(data);
    const pairedFieldSet = new Set(pairs.map(pair => pair.list).flat(1));
    let pairDiffs = [];
    const pickedFields = fields.filter((field) => {
        if (pairedFieldSet.has(field)) {
            return false;
        }
        if (pickFieldSet) {
            return pickFieldSet.has(field);
        }
        if (omitFieldSet) {
            return !omitFieldSet.has(field);
        }
        return true;
    });
    const getFieldDiff = (field, isPairItem) => {
        const originData = get(data.originData, field);
        const compareData = get(data.compareData, field);
        const isFieldDetail = Boolean(data.field);
        if (isEqual(originData, compareData)) {
            return false;
        }
        const curFieldPath = `${isFieldDetail ? combineFieldPath(data, field) : field}`;
        const fieldPath = `${getFieldPath?.(field, { parentData: data }) ?? curFieldPath}`;
        const fieldDetailInfo = schemaContainer.getFieldDetailInfo(data.schemaId, fieldPath);
        const detailName = fieldDetailInfo?.name ?? (data.isSchema ? getFieldInfo(data.appId, data.schemaId, field)?.name : undefined);
        const name = getName ? getName(detailName, { field }) : detailName;
        if (!name) {
            return false;
        }
        const fieldDiff = {
            appId: data.appId,
            schemaId: data.schemaId,
            modifier: data.modifier,
            mtime: data.mtime,
            diffId: data.diffId,
            field,
            title: name || field,
            originData,
            compareData,
            id: field,
            schemaKey: data.key,
            path: `${data.path}${getSeparator(data)}${field}`,
            language: fieldDetailInfo?.lang ?? 'json',
            checkable: isPairItem ? false : mergeCheckable(fieldDetailInfo, data),
            childUncheckable: isPairItem ? true : getDetailUncheckable(fieldDetailInfo, data, Boolean(specialOptions)),
            key: '',
            fieldPath,
            disableTip: true,
            weak: fieldDetailInfo?.weak,
        };
        childNames?.push(fieldDiff.title);
        fieldDiff.key = getDetailedKey(fieldDiff);
        if (indivisibleSchemaSet.has(getDiffIdentity(fieldDiff))) {
            fieldDiff.checkable = false;
        }
        descriptionMap[fieldDiff.key] = descriptionMap[data.key].concat({ propType: DescriptionType.Normal, field });
        Object.assign(fieldDiff, withProperty?.(fieldDiff));
        if (fieldDetailInfo?.type !== FieldType.Indivisible) {
            fieldDiff.children = getFields(fieldDiff, { ...diffOptions, singleEffectOptions: { pairs: fieldDetailInfo?.pairs } }, childrenSpecialDiffOptions);
        }
        return withSlots(fieldDiff);
    };
    const getFieldsDiff = (fields, isPairItem) => fields.map(field => getFieldDiff(field, isPairItem)).filter(Boolean);
    if (pairs.length) {
        pairDiffs = getPairDiff(data, pairs, fields => getFieldsDiff(fields, true), diffOptions);
    }
    return pairDiffs.concat(getFieldsDiff(pickedFields, false));
};
const getSortedKeys = (obj) => Object.keys(obj ?? {}).sort((a, b) => a.localeCompare(b));
export const omitAllNode = (items) => {
    const allNode = items.find(node => node.key === ALL_KEY);
    if (allNode) {
        return allNode.children ?? [];
    }
    return items;
};
export const addAllNode = (items) => (items.length
    ? [
        withSlots({
            title: ALL_TITLE,
            key: ALL_KEY,
            children: items,
            disableTip: true,
        }),
    ]
    : []);
const formatSchema = (data, diffOptions) => {
    const { getSchemaTitle, descriptionMap, childNames } = diffOptions;
    const originData = getFormatDiffContent(data.originData);
    const compareData = getFormatDiffContent(data.compareData);
    if (isEqual(originData, compareData)) {
        return false;
    }
    const { name, getIdentity, type, fieldUncheckable, pickFields, omitFields, pairs } = schemaContainer.getSchemaInfo(data.schemaId) ?? {};
    const anyGet = createAnyGet(data.originData, data.compareData);
    const identitySuffix = getIdentity?.(anyGet) ?? '';
    const title = `${name ?? getSchemaTitle(data.appId, data.schemaId) ?? ''}${identitySuffix}`;
    const schemaDiff = {
        ...data,
        diffId: data.id,
        type: data.type,
        title,
        path: `${title} `,
        key: getDetailedKey(data),
        language: 'json',
        children: [],
        originData,
        compareData,
        checkable: true,
        isSchema: true,
        childUncheckable: fieldUncheckable,
    };
    descriptionMap[schemaDiff.key] = descriptionMap[ALL_KEY].concat([
        {
            propType: DescriptionType.Schema,
            schemaKey: data.schemaKey,
            id: data.id,
            schemaId: data.schemaId,
            type: data.type,
            revertPayload: {
                appId: data.appId,
                schemaId: data.schemaId,
                objectId: data.id,
                desc: getRevertDesc(true),
                versionId: data.versionId ?? '',
                type: data.title,
            },
        },
    ]);
    childNames?.push(schemaDiff.title);
    if (type !== SchemaType.Indivisible) {
        const children = getFields(schemaDiff, {
            ...diffOptions,
            childNames,
            singleEffectOptions: {
                fieldsControl: { pickFields, omitFields },
                pairs,
            },
        });
        schemaDiff.children = children;
    }
    return withSlots(schemaDiff);
};
const getDiffIdentity = ({ appId, schemaId, diffId }) => `${appId}:${schemaId}:${diffId}`;
export const getDetailedDiffTree = (originList, options) => {
    const list = cloneDeep(originList);
    const group = groupBy(list, 'schemaKey');
    const childNamesMap = {};
    const mergedOptions = { ...options, descriptionMap: {}, indivisibleSchemaSet: new Set() };
    const { descriptionMap, indivisibleSchemaSet } = mergedOptions;
    const schemaTitleMap = createSchemaTitleMap();
    descriptionMap[ALL_KEY] = [{ propType: DescriptionType.All }];
    const sortedKeys = getSortedKeys(group);
    const keySet = new Set(sortedKeys);
    const priorityKeys = ['page', 'collection'];
    const priorityKeySet = new Set(priorityKeys);
    const schemaKeys = priorityKeys
        .filter(key => keySet.has(key))
        .concat(sortedKeys.filter(key => !priorityKeySet.has(key)));
    const children = schemaKeys
        .map((schemaKey) => {
        const childNames = [];
        childNamesMap[schemaKey] = childNames;
        const options = Object.assign({ childNames }, mergedOptions);
        const items = group[schemaKey];
        items.forEach((item) => {
            // 新建和删除行不支持细分，字段信息不全无法进行发版/撤回
            if (item.type !== DiffType.UPDATE) {
                indivisibleSchemaSet.add(getDiffIdentity({ ...item, diffId: item.id }));
            }
        });
        const baseData = withSlots({
            title: items[0].title,
            key: items[0].schemaKey,
            disableTip: true,
        });
        if (items.length === 1) {
            const ret = formatSchema(items[0], options);
            if (ret) {
                schemaTitleMap[schemaKey] = ret.title;
            }
            return ret;
        }
        schemaTitleMap[schemaKey] = baseData.title;
        descriptionMap[baseData.key] = [{ propType: DescriptionType.Meaningless }];
        const ret = {
            ...baseData,
            children: items.map(item => formatSchema(item, options)).filter(Boolean),
        };
        if (ret.children.length) {
            return ret;
        }
        return false;
    })
        .filter(Boolean);
    const data = addAllNode(children);
    return { data, descriptionMap, childNamesMap, schemaTitleMap };
};
const defaultMergeOptions = {
    reserveRevertPayload: false,
    reserveKeys: false,
};
export function mergeDescriptions(keys, descriptionMap, opts) {
    const pairKeySet = new Set();
    const parsedKeys = keys.concat(...keys.map((key) => {
        const leafDescription = last(descriptionMap[key]);
        if (leafDescription?.propType === DescriptionType.Pair) {
            pairKeySet.add(key);
            return leafDescription.keys;
        }
        return [];
    }));
    const descriptions = parsedKeys
        .filter(key => !pairKeySet.has(key))
        .map(key => ({ key, description: descriptionMap[key] }));
    const options = Object.assign({}, defaultMergeOptions, opts);
    const collectorMap = {};
    const combinePath = (...partPaths) => partPaths.join('.');
    const getPath = (...description) => {
        const path = combinePath(...description.map((d) => {
            switch (d.propType) {
                case DescriptionType.All:
                    return `${d.propType}`;
                case DescriptionType.Schema:
                    return `${d.schemaKey}:${d.id}`;
                case DescriptionType.Normal:
                    return d.field;
                case DescriptionType.Array:
                    return `[${d.originIdx}-${d.compareIdx}]`;
                default:
                    throw new Error('miss to handle some DescriptionType');
            }
        }));
        return path;
    };
    let rootCollector;
    const ignoreDescriptionTypeSet = new Set([DescriptionType.Meaningless, DescriptionType.Pair]);
    descriptions.forEach(({ description, key }) => {
        let prevPath;
        let prevCollector;
        const meaningfulDescription = description.filter(d => !ignoreDescriptionTypeSet.has(d.propType));
        meaningfulDescription.some((d, i) => {
            const path = getPath(d);
            const curPath = prevPath ? combinePath(prevPath, path) : path;
            const isRoot = d.propType === DescriptionType.All;
            const isSchema = d.propType === DescriptionType.Schema;
            const shouldCollectKey = isSchema && options.reserveKeys;
            if (!collectorMap[curPath]) {
                collectorMap[curPath] = Object.assign({ children: [], childPathSet: new Set() }, shouldCollectKey ? { keys: [] } : undefined, d);
            }
            const curCollector = collectorMap[curPath];
            // 记录每行下面细分的keys用于撤销计数和部分成功场景
            if (shouldCollectKey) {
                curCollector.keys?.push(key);
            }
            const isLast = i === meaningfulDescription.length - 1;
            if (!curCollector.isLeaf) {
                curCollector.isLeaf = isLast && !isRoot;
            }
            if (isRoot) {
                rootCollector = curCollector;
            }
            if (prevCollector && !prevCollector.isLeaf && !prevCollector.childPathSet.has(path)) {
                prevCollector.children?.push(curCollector);
                prevCollector.childPathSet.add(path);
            }
            if (prevCollector?.isLeaf) {
                return true;
            }
            prevCollector = curCollector;
            prevPath = curPath;
            return false;
        });
    });
    const clearProps = (collectors) => {
        collectors?.forEach((collector) => {
            if (collector.isLeaf) {
                delete collector.children;
            }
            if (collector.propType === DescriptionType.Schema) {
                if (!options.reserveRevertPayload) {
                    delete collector.revertPayload;
                }
            }
            delete collector.childPathSet;
            delete collector.isLeaf;
            clearProps(collector.children);
        });
    };
    if (rootCollector?.propType === DescriptionType.All) {
        clearProps(rootCollector.children);
        return rootCollector.children;
    }
    return [];
}
export function deepPickTree(tree, keys, options = {}) {
    const keySet = new Set(keys.concat(ALL_KEY));
    const pathKeySet = new Set();
    const walk = (item, keyPath = []) => {
        if (keySet.has(item.key)) {
            keyPath.forEach((k) => {
                if (!keySet.has(k)) {
                    pathKeySet.add(k);
                }
            });
        }
        item.children?.forEach(child => walk(child, keyPath.concat(item.key)));
    };
    tree.forEach(item => walk(item));
    const pickTree = (tree) => tree
        ?.map(item => ({ ...item, disabled: options.disablePathNode && pathKeySet.has(item.key) }))
        .filter((item) => {
        const isPath = pathKeySet.has(item.key);
        const isChecked = keySet.has(item.key);
        if (!isChecked || item.key === ALL_KEY) {
            item.children = pickTree(item.children);
        }
        return isChecked || isPath;
    });
    return pickTree(tree) ?? [];
}
export const getTreeMap = (items, formatValue) => {
    const map = {};
    const walk = (items) => {
        items?.forEach((c) => {
            map[c.key] = (formatValue ? formatValue(c) : c);
            walk(c.children);
        });
    };
    walk(items);
    return map;
};
export const getSearchJSON = (item) => JSON.stringify([item.title].concat(item.children?.length ? [] : [item.compareData, item.originData]));
export const getTreeKeys = (item) => Object.keys(getTreeMap([item].flat(1)));
export function countKey(details) {
    return details?.map(d => d.keys?.length ?? 0).reduce((prev, cur) => prev + cur, 0) ?? 0;
}
export function convertDescriptionToDiscardPayload(detail) {
    return { ...detail.revertPayload, description: omit(detail, 'revertPayload', 'keys') };
}
export function filterHalfCheckedKeys(tree, keys) {
    const keySet = new Set(keys);
    const walk = (items) => {
        items?.forEach((item) => {
            const { key } = item;
            if (!keySet.has(key) || !item.children?.length) {
                return;
            }
            walk(item.children);
            if (!item.children.every(child => child.checkable === false || keySet.has(child.key))) {
                keySet.delete(key);
            }
        });
    };
    walk(tree);
    return [...keySet];
}
