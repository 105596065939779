import { FieldType, Lang } from '../type';
export const schema = {
    id: 'page',
    name: '页面',
    pickFields: ['pageConfig', 'title'],
    identityField: ['name', 'path'],
    getIdentity: (name, path) => `【${name}（${path}）】`,
    fields: {
        pageConfig: {
            type: FieldType.Array,
            identityField: ['name', 'id'],
            idField: 'id',
            getIdentity: (name, id) => `${name}（${id}）`,
            detailUncheckable: true,
            details: {
                lessCode: {
                    name: 'LessCode',
                    lang: Lang.Javascript,
                    weak: true,
                },
                layout: { name: '页面布局', weak: true },
                xySources: { name: '页面数据', weak: true },
            },
        },
    },
};
