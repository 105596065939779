import { ref } from '@vue/composition-api';
import { useReactiveMap } from '@/hooks/useReactiveMap';
import dependencyLoader from '@utils/dependencyLoader';
import { arrayify } from '@tencent/ui-core/lib/utils';
import openProviderModal from './provider-modal';
import { ConsumerMessageType, ProviderMessageType, PROVIDER_SOURCE, CONSUMER_SOURCE, BROADCAST_CHANNEL_ID, } from './consts';
/**
 * 编辑器使用的 previewProvider
 *
 * provider的意思是提供页面信息的一方
 */
export const usePreviewProvider = (previewSessionKey) => {
    // 我们只能在createdHook中获取到editor实例，所以这里需要一个全局变量来存储editor实例
    let providerEditor = null;
    const getPayload = () => ({
        baseData: providerEditor?.baseData,
        projectInfo: providerEditor?.projectInfo,
    });
    const basePreviewer = usePreviewBaseProvider(previewSessionKey, getPayload);
    const editorCreatedHook = (editor) => {
        providerEditor = editor;
        basePreviewer.createHook();
    };
    const editorDestroyedHook = () => {
        basePreviewer.destroyHook();
    };
    const updatePageConfig = () => {
        basePreviewer.postMessage(getPayload(), ProviderMessageType.updatePageConfig);
    };
    const switchActiveKeyHook = (_editor, key) => {
        if (key === 'renderer') {
            updatePageConfig();
        }
    };
    return {
        ...basePreviewer,
        editorCreatedHook,
        editorDestroyedHook,
        switchActiveKeyHook,
        updatePageConfig,
    };
};
/**
 * 用于预览模式的基础数据提供者
 *
 * 用于拓展不同场景下需要使用预览模式的组件
 *
 * @param previewSessionKey 用于区别不同的预览会话
 * @returns
 */
export const usePreviewBaseProvider = (previewSessionKey, getPayload) => {
    const broadcastChannel = ref(null);
    let hookId = null;
    const connectedPreviewers = useReactiveMap();
    const providerEnabled = ref(true);
    let pingCheckKillList = new Set();
    const pingCheckTimer = setInterval(() => {
        if (document.visibilityState !== 'visible')
            return;
        pingCheckKillList.forEach((previewerId) => {
            connectedPreviewers.delete(previewerId);
        });
        pingCheckKillList = new Set(connectedPreviewers.data.keys());
        postMessage({}, ProviderMessageType.ping);
    }, 30e3);
    const sendDataSourceUpdate = () => {
        postMessage({}, ProviderMessageType.updateDataSource);
    };
    const createHook = () => {
        broadcastChannel.value = new BroadcastChannel(`${BROADCAST_CHANNEL_ID}_${previewSessionKey}`);
        broadcastChannel.value.onmessage = (event) => {
            const { source, messageType, previewerId } = event.data;
            if (source === CONSUMER_SOURCE && previewerId) {
                let previewer = connectedPreviewers.get(previewerId);
                if (!previewer) {
                    previewer = {
                        id: previewerId,
                        postMessage: (payload, messageType) => postMessage(payload, messageType, [previewerId]),
                    };
                    connectedPreviewers.set(previewerId, previewer);
                }
                // handle messages from a previewer
                switch (messageType) {
                    case ConsumerMessageType.pong:
                        pingCheckKillList.delete(previewerId);
                        break;
                    case ConsumerMessageType.fetchConfig:
                        if (!providerEnabled.value) {
                            return;
                        }
                        previewer.postMessage(getPayload(), ProviderMessageType.updatePageConfig);
                        break;
                    case ConsumerMessageType.closed:
                        connectedPreviewers.delete(previewerId);
                        break;
                    case ConsumerMessageType.editorCreatedAck:
                        // do nothing - 上面添加previewer的逻辑已经会记录这个previewer了，目前不需要做其他事情
                        break;
                }
            }
            // 同一个channel id下只允许有一个provider，如果有新的provider创建，将之前的provider关闭
            if (source === PROVIDER_SOURCE
                && messageType === ProviderMessageType.providerCreated) {
                providerEnabled.value = false;
                openProviderModal(null).then((res) => {
                    if (!res.success) {
                        return;
                    }
                    activeProvider();
                });
            }
        };
        postMessage({}, ProviderMessageType.providerCreated);
        hookId = dependencyLoader.hooks.register.tap('updateDep', (module) => {
            postMessage(module, ProviderMessageType.updateDependency);
        });
        window.addEventListener('beforeunload', destroyHook);
        window?.xy_event_bus?.$on('collection-update', sendDataSourceUpdate);
    };
    const destroyHook = () => {
        window.removeEventListener('beforeunload', destroyHook);
        clearInterval(pingCheckTimer);
        if (broadcastChannel.value) {
            postMessage({}, ProviderMessageType.providerDestroyed);
            broadcastChannel.value.close();
            broadcastChannel.value = null;
        }
        dependencyLoader.hooks.register.untap(hookId);
        window?.xy_event_bus?.$off('collection-update', sendDataSourceUpdate);
    };
    const postMessage = (payload, messageType, targetPreviewerIds) => {
        broadcastChannel.value?.postMessage({
            messageType,
            source: PROVIDER_SOURCE,
            payload,
            targetPreviewerIds: targetPreviewerIds?.length
                ? arrayify(targetPreviewerIds)
                : null,
        });
    };
    // 激活当前provider
    const activeProvider = () => {
        providerEnabled.value = true;
        postMessage({}, ProviderMessageType.providerCreated);
        setTimeout(() => {
            postMessage(getPayload(), ProviderMessageType.updatePageConfig);
        }, 500);
    };
    return {
        createHook,
        destroyHook,
        postMessage,
        connectedPreviewers,
        providerEnabled,
        activeProvider,
    };
};
