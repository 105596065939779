import { cloneDeep, isPlainObject, keyBy } from 'lodash';
import projectLoader from '@loaders/project/loader';
import pageLoader from '@loaders/page/loader';
import { preLoader } from '../loaders/runtimePreloader';
import { BASE_PATH, BASE_XY_API_PATH } from '@/config/constant';
import logger from '@/utils/logger';
import wujiFetch from '@/utils/wujiFetch';
import { isMockAsGeneralUser } from '@utils/userInfo';
import { formatToUiProjectInfo } from '@loaders/project/utils';
import getXyManagePathPrefix from '@/utils/getXyManagePathPrefix';
import { runtimeHook } from '@/hooks/runtimeHook';

// 不需要接口序列化的字段, 在使用的时候再序列化
const pageExcludeJsonFields = ['pageConfig'];

// Magic Number: 针对少量应用
const maxPreloadPageSize = 500;

const getPageMapKey = ({ projectId, pageId, env, branch }) => `${projectId}:${pageId}:${env}${branch && branch !== 'master' ? `:${branch}` : ''}`;

export default {
  name: 'runtime',
  namespaced: true,
  state: {
    mode: '',
    project: null,  // 应用信息
    basicPages: null, // 应用页面列表(Basic)
    pagesMap: {}, // 应用页面kv映射对象
    pageInfo: {
      currentPageId: '',
    },
    currentPage: null,
    topSwitch: {},
    datatable: {},
  },
  getters: {
    currentPageId: state => state.pageInfo.currentPageId,
  },
  actions: {
    // 获取应用数据
    async getProject({ commit, state }, { projectId, env }) {
      let { project } = state;
      // 1. 从 vuex 取, 优先返回
      if (project) return project;

      if (window?.xy_runtime_project?.id) {
        // 2. 从 window 取 (直出阶段注入到 window 变量中)
        project = formatToUiProjectInfo(window.xy_runtime_project);
      } else {
        // 3. 从接口取
        project = await projectLoader.fetchBasicProjectInfo(projectId, env);
      }
      if (!project?.id) {
        location.href = `${BASE_PATH}projectNotFound`;
      }
      commit('setter', { key: 'project', value: project });
      return project;
    },
    async getGroupInfo({ commit, state, dispatch }, { projectId, env }) {
      const cacheKey = 'cache:groupInfo';
      if (state[cacheKey]) return state[cacheKey];

      const { groupId } = await dispatch('getProject', { projectId, env });
      const ans = groupId
        ? await wujiFetch(`${BASE_XY_API_PATH}/runtime/group/${groupId}/base`, { method: 'GET' })
        : {
          groupId: '',
          logo: '',
          name: '',
          isAdmin: false,
        };

      if (ans.isAdmin && isMockAsGeneralUser) ans.isAdmin = false;

      commit('setter', { key: cacheKey, value: ans });
      return ans;
    },
    // 获取页面列表(Basic)
    async getBasicPageList({ commit, state }, { projectId, env }) {
      let { basicPages } = state;

      // 1. 从 vuex 取, 优先返回
      if (basicPages) return basicPages;

      if (window.xy_runtime_pages && !pageLoader.isDebug()) {
        // 2. 从 window 取 (直出阶段注入到 window 变量中)
        basicPages = window.xy_runtime_pages;
      } else {
        // 3. 从接口取
        basicPages = await pageLoader.loadBasicList(
          projectId,
          { query: { size: 'total' } },
          env,
        );
      }

      commit('setter', { key: 'basicPages', value: Object.freeze(basicPages) });
      return basicPages;
    },
    // 初始化页面列表(完整)
    async initPageList({ commit, state }, { projectId, env, branch }) {
      // 如果应用页面太多则不提前加载所有页面，减少内存占用
      if (state.basicPages?.length > maxPreloadPageSize) return;

      const list = await pageLoader.loadList(
        projectId,
        { query: { size: 'total', exclude_json: pageExcludeJsonFields.join(',') } },
        env,
      );

      const map = keyBy(list, page => getPageMapKey({ projectId, pageId: page.pageId, env, branch }));
      commit('setter', { key: 'pagesMap', value: Object.freeze(map) });
    },
    // 获取单个页面数据(完整)
    async getPageDetail({ state }, { projectId, pageId, env, branch }) {
      const key = getPageMapKey({ projectId, pageId, env, branch });
      let page;
      const pagePromise = window.xy_runtime_pageInfoCache.get(key);
      if (pagePromise) {
        page = await pagePromise;
      }
      if (!page) {
        page = state.pagesMap[key];
      }

      // 检查字段是否已经被序列化
      if (page) {
        pageExcludeJsonFields.forEach((field) => {
          if (typeof page[field] === 'string') {
            try {
              page[field] = JSON.parse(page[field]);
            } catch (err) {
              logger.error('[getPageDetail] JSON.parse 失败', field, page);
            }
          }
        });
      }

      if (!page) {
        page = await pageLoader.getPageDetail({ projectId, pageId });
      }

      // 预加载数据源
      try {
        preLoader.preloadByPageInfo(page, env);
      } catch (error) {
        logger.error(error);
      }
      const res = cloneDeep(page);
      if (Array.isArray(res?.pageConfig)) {
        res.pageConfig.forEach((item) => {
          Object.defineProperty(item, '_isVue', {
            value: true,
            enumerable: false,
          });
        });
      }

      await runtimeHook.pageConfigLoaded.promise(res);
      return res;
    },

    async getDataTableByPageId({ commit }, { projectId, pageId, env }) {
      try {
        const data = await wujiFetch(`${getXyManagePathPrefix({ envId: env })}/datatable/page/${pageId}?projectid=${projectId}&join=page`);
        commit('setter', { key: 'datatable', value: data });
        return data;
      } catch (e) {
        logger.error(e);
      }
    },
  },
  mutations: {
    setter(state, { key, value, merge = false }) {
      if (merge) {
        if (Array.isArray(value)) {
          state[key] = [...state[key], ...value];
        } else if (isPlainObject(value)) {
          state[key] = { ...state[key], ...value };
        }
      } else {
        state[key] = value;
      }
    },
    setMode(state, mode) {
      Object.assign(state, { mode });
    },
    setPageInfo(state, payload) {
      const pageInfo = { ...state.pageInfo, ...payload };
      Object.assign(state, { pageInfo });
    },
    setCurrentPage(state, page) {
      state.currentPage = page;
    },
    setTopSwitch(state, payload) {
      const topSwitch = { ...state.topSwitch, ...payload };
      Object.assign(state, { topSwitch });
    },
  },
};
