import { omit } from 'lodash';
import { message } from 'ant-design-vue';
import qs from 'query-string';

import wujiFetch from '@utils/wujiFetch';
import { addPathPrefix } from '@/utils/path';
import { getDesignEnvPath } from '@config/constant';
import { convertDescriptionToDiscardPayload } from '@/components/version/utils/diff-tree';

const getPathPrefix = env => `${getDesignEnvPath(env)}/version`;
const PAGE_SIZE = 10;

export const VERSION_STATUS = {
  UNRELEASED: 0, // 未发布or下线了
  RELEASED: 1, // 已发布
  IN_GRAY: 2, // 灰度
  PRE_RLEASED: 3, // 预发布（只有正式环境有这个状态）
  REJECT: 4, // 打回（只有正式环境有），在预发布状态选择了取消发布
};

export const VERSION_ID_QUERY_KEY = 'versionId';
export const VERSION_ENV_QUERY_KEY = 'currentEnv';
export const VERSION_SEARCH_QUERY_KEY = 'versionSearch';

const getSearch = search => (search ? `&search=${search}` : '');

const getReleasedVersion = async (env, projectId) => await wujiFetch(`${getPathPrefix(env)}/project/release?projectid=${projectId}`);
export const fetchLatestSemver = async ({ projectId, env }) => {
  const url = `${getPathPrefix(env)}/project/semver/latest`;
  const result = await wujiFetch(
    `${url}?projectid=${projectId}`,
    { method: 'GET', timeout: 5000 },
  );
  return result?.[0]?.semver ?? '';
};
export const fetchVersionList = async ({ projectId, env, page = 1, size = PAGE_SIZE, search }) => {
  const url = `${getPathPrefix(env)}/project`;
  const result = await wujiFetch(
    `${url}?projectid=${projectId}&page=${page}&size=${size}&sort=_ctime&order=desc${getSearch(search)}`,
    { method: 'GET', timeout: 5000 },
  );
  return result;
};
const timeout = 60000 * 5; // 5分钟

export default {
  state: {
    versionList: {},
    currentVersion: {},
    fromReleasedVersion: {},
    envPageList: [],
    total: 0, // 总数
    page: 1, // 当前页
    search: undefined, // 搜索条件
    fieldsMap: {}, // 发布版本字段名映射
  },
  getters: {
  },
  actions: {
    /**
     * 获取两个环境 / 自定义分支与环境的diff
     */
    async fetchDiffContent({ commit, rootState }, { projectId, fromEnv, fromBranch, toEnv }) {
      let diffList = [];
      const proId = projectId || rootState.project.projectInfo?.id;
      try {
        if (fromEnv === 'dev') {
          if (fromBranch && fromBranch !== 'master') {
            // 分支发布
            const url = `${getPathPrefix(toEnv)}/project/branch/${fromBranch}/staged`;
            diffList = await wujiFetch(`${url}?projectid=${proId}`, { method: 'GET', timeout });
          } else {
            // 开发环境发布
            const url = `${getPathPrefix(toEnv)}/project/staged`;
            diffList = await wujiFetch(`${url}?projectid=${proId}`, { method: 'GET', timeout });
          }
        } else {
          const fromReleasedVersion = await getReleasedVersion(fromEnv, proId);
          commit('updateVersionState', { fromReleasedVersion: fromReleasedVersion || {} });
          if (fromReleasedVersion?.id) {
            const currentVersionId = fromReleasedVersion.id;
            const targetVersion = await wujiFetch(`${getPathPrefix(toEnv)}/project/release?projectid=${proId}`, { timeout });
            diffList = await wujiFetch(`${getPathPrefix(fromEnv)}/project/compare?projectid=${proId}&fromVersionId=${currentVersionId}&toVersionId=${targetVersion?.id || ''}`, {
              timeout,
            });
          } else {
            message.warning(`${fromEnv} 环境暂未发布版本，当前无可比较的版本`);
          }
        }
        return diffList;
      } catch (err) {
        message.error(`获取当前页面版本差异失败:${err.message}`);
      }
    },

    async fetchFieldsMap({ rootState, commit }, { env, projectId }) {
      const pId = projectId || rootState.project.projectInfo?.id;
      const url = `${getPathPrefix(env)}/project/fieldsMap?projectid=${pId}`;
      const fieldsMap = await wujiFetch(url, { method: 'GET', timeout });
      commit('updateVersionState', { fieldsMap });
    },


    /**
     *  提交应用版本（只创建不发布）
     */
    async createProjectVersion({ dispatch, rootState }, { projectId, env, branch, semver, desc, data, all, detailed }) {
      const proId = projectId || rootState.project.projectInfo?.id;
      const query = { projectid: proId };
      // 精简数据：一般发布只用到 id 和 schemaKey, schemaId 和 type 用于判断是否为新项目发布
      const getFixData = () => (Array.isArray(data) ? data.map(item => omit(item, ['compareData', 'originData'])) : data);
      if (branch !== 'master') query.branch = branch;
      const result = await wujiFetch(`${getPathPrefix(env)}/project?${qs.stringify(query)}`, {
        method: 'POST',
        body: JSON.stringify({
          semver,
          desc,
          data: detailed ? data : getFixData(),
          all,
          detailed,
        }),
        timeout,
      });

      if (result && result.id) {
        await dispatch('publishProjectVersion', { projectId: proId, env, versionId: result.id });
      } else {
        throw new Error(`提交版本失败:${result}`);
      }
    },

    /**
     *  获取应用版本列表
     */
    async fetchProjectVersionList({ commit, state }, { projectId, env, page = 1, search }) {
      try {
        const url = `${getPathPrefix(env)}/project`;
        const getTotal = wujiFetch(
          `${url}?projectid=${projectId}&count=1${getSearch(search)}`,
          { method: 'GET', timeout: 5000 },
        );
        const searchChanged = state.search !== search;
        const expectedPage = searchChanged ? 1 : page;
        const getList = fetchVersionList({ projectId, env, page: expectedPage, search });
        const [result, { total }] = await Promise.all([getList, getTotal]);
        commit('updateVersionState', { page: expectedPage, total, search });
        commit('updateVersionList', { env, versions: result });
      } catch (err) {
        message.error(`获取版本列表失败:${err.message}`);
      }
    },

    /**
     * 发布应用版本
     */
    async publishProjectVersion({ }, { projectId, env, versionId }) {
      try {
        await wujiFetch(
          `${getPathPrefix(env)}/project/release/${versionId}?projectid=${projectId}`,
          {
            method: 'POST',
            timeout,
          },
        );
      } catch (e) {
        throw new Error(`发布版本失败:${e.message}`);
      }
    },

    // 获取应用当前版本
    async fetchCurrentProjectVersion({ commit }, { projectId, env }) {
      try {
        const url = `${getPathPrefix(env)}/project/release`;
        const result = await wujiFetch(`${url}?projectid=${projectId}`, { method: 'GET' });
        commit('updateVersionState', { currentVersion: result });
      } catch (err) {
        message.error(`获取当前应用版本列表失败:${err.message}`);
      }
    },

    /**
     * 应用环境同步
     */
    async syncProjectVersion({ }, { projectId, versionId, fromEnv, toEnv, data, desc, semver, detailed }) {
      try {
        // 如果没有versionId，默认同步当前版本到其他环境
        let verId = versionId;
        if (!versionId) {
          const releasedVersion = await getReleasedVersion(fromEnv, projectId);
          verId = releasedVersion.id;
        }
        // 精简数据：一般发布只用到 id 和 schemaKey
        const getFixData = () => (Array.isArray(data) ? data.map(item => omit(item, ['compareData', 'originData'])) : data);
        const url = `${getPathPrefix(fromEnv)}/project/sync`;
        return wujiFetch(
          `${url}/${verId}?projectid=${projectId}`,
          {
            method: 'POST',
            body: JSON.stringify({
              from: fromEnv,
              to: toEnv,
              data: detailed ? data : getFixData(),
              detailed,
              desc,
              semver,
            }),
            // 这个接口在大型应用耗时会久些，所以延长下超时时间避免前端报错实际接口请求成功
            timeout,
          },
        );
      } catch (err) {
        const res = err.response ? await err.response.json() : { error: err.message };
        throw new Error(`同步环境数据失败:${res.error}`);
      }
    },

    /**
     * 获取已发布的版本提交内容
     */
    async fetchVersionCommitContent({ }, { projectId, env, versionId }) {
      try {
        // 发布页面版本
        return await wujiFetch(`${getPathPrefix(env)}/project/diff/${versionId}?projectid=${projectId}`);
      } catch (e) {
        throw new Error('获取版本发布内容失败');
      }
    },

    /**
     * 中断发布正式
     */
    async cancelPublish({ }, { projectId, versionId, env }) {
      try {
        await wujiFetch(`${getPathPrefix(env)}/project/cancel/${versionId}?projectid=${projectId}`, { method: 'POST' });
      } catch (err) {
        throw new Error(`版本终止失败:${err.message}`);
      }
    },

    /**
     * 撤销（开发环境的）某条变更
     */
    async discardChange({ }, { projectId, env, versionId, appId, schemaId, objectId, desc, type }) {
      try {
        // 变更先暂存到stash
        await wujiFetch(`${getPathPrefix('dev')}/common/stash?projectid=${projectId}`, {
          method: 'POST',
          body: JSON.stringify({
            appId,
            schemaId,
            objectId,
            desc,
          }),
        });
        // 再去更新开发环境的数据
        await wujiFetch(`${getPathPrefix(env)}/common/apply?projectid=${projectId}`, {
          method: 'POST',
          body: JSON.stringify({
            appId,
            schemaId,
            versionId,
            objectId,
            type,
          }),
        });
        return true;
      } catch (err) {
        console.error(err);
        message.error(`操作失败${err.message}`);
        return false;
      }
    },

    /**
     * 批量撤销（开发环境的）某条变更
     */
    async batchDiscardChange({ }, { env, projectId, payloads, detailed, detailedPayloads, batch = false }) {
      try {
        return await wujiFetch(`${getPathPrefix(env)}/common/revert?projectid=${projectId}`, {
          method: 'POST',
          body: JSON.stringify({
            batch,
            detailed,
            ...(detailed
              ? { detailedPayloads: detailedPayloads.map(convertDescriptionToDiscardPayload) }
              : { payloads }) }),
          timeout: Math.max(((detailed ? detailedPayloads?.length : payloads?.length) ?? 0) * 3000, 10000),
        });
      } catch (err) {
        console.error(err);
        message.error(`操作失败${err.message}`);
      }
    },

    async exportVersion({ rootState }, { env, versionId, query = {} }) {
      const projectId = rootState.project.projectInfo.id;
      const params = new URLSearchParams({
        projectid: projectId,
        ...query,
      });
      window.open(`${addPathPrefix(getPathPrefix(env))}/project/download/${versionId}?${params}`, '_blank');
    },
    async importVersion({ rootState, dispatch }, { env, version, desc, beforeFunc }) {
      try {
        const projectId = rootState.project.projectInfo.id;
        const url = `${getPathPrefix(env)}/project/import?projectid=${projectId}`;
        const result = await wujiFetch(url, {
          method: 'POST',
          body: JSON.stringify({
            version,
            desc,
            beforeFunc,
          }),
          timeout,
        });
        message.success('导入成功');
        dispatch('fetchProjectVersionList', {
          projectId,
          env,
        });
        return result;
      } catch (err) {
        message.error(`导入失败: ${err?.message ?? ''}`);
        return null;
      }
    },
    async debugImportHook({ rootState }, { env, data, beforeFunc }) {
      try {
        const projectId = rootState.project.projectInfo.id;
        const url = `${getPathPrefix(env)}/project/hook/debug?projectid=${projectId}`;
        const result = await wujiFetch(url, {
          method: 'POST',
          body: JSON.stringify({
            data,
            beforeFunc,
          }),
          timeout,
        });
        return result;
      } catch (err) {
        message.error(`执行前置钩子失败: ${err?.message ?? ''}`);
        throw err;
      }
    },
    async fetchImportHooks({ rootState }) {
      try {
        const projectId = rootState.project.projectInfo.id;
        const url = `${getPathPrefix('dev')}/project/hook?projectid=${projectId}`;
        const result = await wujiFetch(url);
        return result;
      } catch (err) {
        message.error(`获取导入钩子失败: ${err?.message ?? ''}`);
        throw err;
      }
    },
  },
  mutations: {
    updateVersionList(state, { env, versions }) {
      const { versionList } = state;
      versionList[env] = versions;
      Object.assign(state, { versionList: { ...versionList } });
    },
    updateVersionState(state, payload) {
      return Object.assign(state, payload);
    },
  },
};
