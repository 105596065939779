import { ref, computed } from '@vue/composition-api';
import { defineInjection } from '@/utils/vue/injection';
import { fetchAuthSetting } from '@/composables/useAuthSetting';
import { hasPanguMapping, canManageInXy, hasPowerMapping } from './utils';
const usePermState = () => {
    const panguMappingEnabled = ref(false);
    const canManagePermInXy = ref(false);
    const powerMappingEnabled = ref(false);
    let authSetting = null;
    const initAuthSetting = async (projectId, envId) => {
        authSetting = await fetchAuthSetting(projectId, true);
        initState(envId);
    };
    const initState = (envId) => {
        canManagePermInXy.value = canManageInXy(authSetting, envId);
        powerMappingEnabled.value = hasPowerMapping(authSetting, envId);
        panguMappingEnabled.value = hasPanguMapping(authSetting, envId);
    };
    const getPanguId = (envId) => {
        const envInfo = authSetting?.envMapping?.[envId];
        return envInfo?.panguId;
    };
    const getPowerSystemId = (envId) => {
        const envInfo = authSetting?.envMapping?.[envId];
        return envInfo?.systemId;
    };
    const hasProxyAuthEnabled = computed(() => panguMappingEnabled.value || powerMappingEnabled.value);
    return {
        initAuthSetting,
        initState,
        panguMappingEnabled,
        powerMappingEnabled,
        hasProxyAuthEnabled,
        canManagePermInXy,
        getPanguId,
        getPowerSystemId,
    };
};
export const { provideFunc: providePermState, injectFunc: injectPermState, } = defineInjection(usePermState, '@xy-client/usePermState');
