import { isEmpty } from 'lodash';

const collDBFields = (collection, cgiId) => {
  const result = collRemoteDBFields(collection);
  if (result.length) return result;

  const cgi = collection?.cgiInfo?.[cgiId];
  if (!cgi) return [];
  const { clipperSchema = '' } = cgi;
  const { settings: { clipperSchema: { clippers = [] } } } = collection;
  const clipper = clippers.find(c => c.id === clipperSchema);
  if (!clipper) return [];
  const { fields = [] } = clipper.schema || clipper.originalSchema;
  return fields;
};

const collRemoteDBFields = collection => collection?.entity?.fields ?? [];

const collHTTPFields = (collection, cgiId) => {
  const result = collRemoteDBFields(collection);
  if (result.length) return result;

  const { responseSchema, fixedResponseSchema = {} } = collection.cgiInfo[cgiId] ?? {};
  const schema = isEmpty(fixedResponseSchema) ? responseSchema : fixedResponseSchema;
  const { fields: wrapper } = schema;
  const dataField = wrapper.find(i => i.id === 'data');
  const realFields = dataField?.fields ? dataField.fields : wrapper;
  return realFields;
};

export const getDsFields = (form, collections) => {
  if (form.dataType !== 'datasource') return [];
  const { collectionId, cgiId } = form;
  const collection = collections.find(c => c.collectionId === collectionId);
  if (!collection) return [];
  if (collection.type === 'db') return collDBFields(collection, cgiId);
  if (collection.type === 'wuji1_0') return collRemoteDBFields(collection);
  return collHTTPFields(collection, cgiId);
};

export const DataType = {
  DATASOURCE: 'datasource',
  WUJI_DB: 'wuji-db',
  JSON: 'json',
};

export const getWorkflowNotificationPluginList = () => {
  const plugins = window.GLOBAL_INFO?.deploySettingFlag?.workflowNotificationPlugins ?? [];
  return plugins;
};
